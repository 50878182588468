import { createStore } from 'vuex'
import axios from 'axios'
import store from './index';

//tecnico recursos humanos y tecnico comercio exterior se consideran en ing rrhh e ing comex
// fin configuracion de sockets
export default createStore({
  state: {
    logged:false,
    nftmarket:[

    ],
    registerNftModal:false,
    languageModal:false,
    modalForgotPassword:false,
    messageModal:false,
    clicked:false,
    sessionExpired:false,
    modalVenderToken:false,
    notRegisteredAssets:"empty",
    registerAssetModal:false,
    registerAsset:{},
    usernameModalClicked:false,
    assetAvender:false,
    modalTOTP: {open:false,link:""},
    selectAssetModal:{clicked:false,asset:""},
    selectNftModal:{clicked:false,nft:""},
    pendingToken:{clicked:false,asset:{},firstClick:false,status:""},
    blockedAccount:false,
    buyAssetCantidad:0,
    cargando:false,
    wallet:{raptoreum:'none'},
    rtmBalance:0,
    withdrawModalClicked:false,
    tokenquery:"",
    createAssetModalClicked:false,
    withdrawAmount:0,
    withdrawAddress:"",
    loginUsername:'',
    loginPassword:'',
    createTokenTotal:0,
    busy:{status:false,reasons:[]},
    createTokenName:"",
    createTokenAcronym:"",
    userAssets:[] ,
    totalAssets:[],
    marketassets:[],
    usuario: false,
    registerSuccess:false,
    token:localStorage.getItem("token") || false,
    idioma:localStorage.getItem("idioma") || false,
    isRWC:false,
    totp:null,
    totpCode:"",
    registeredCorrectly:false
  },
  getters: {
  },
  mutations: {
   goToHome:()=>{
    this.$router.push('homeView')
    },
    isClicked:async(state)=>{
          state.clicked=true
    },
    REMOVE_DATA(state){
      console.log("ejecutando   REMOVE_DATA")
      localStorage.removeItem("token")
      state.logged=false
      state.usuario=""
    },
    SET_DATA(state,data) { 
 
      console.log("ejecutando SET_DATA:",data)
      state.logged=true
      localStorage.setItem("token",data.token) 
      state.usuario=data.usuario
      state.totp=data.totp
    },
    DONT_LOG(state) { 
      state.logged=false
      localStorage.removeItem("token")
    },
    UPDATE_IDIOMA(state){
      state.idioma=localStorage.getItem("idioma")
    }
},
  actions: {
    dontLog({ commit }){
        commit('DONT_LOG');
      },
      setTokenEnglish({ commit, state }, payload) {
        state.languageModal = false; 
        localStorage.removeItem("idioma");
        localStorage.setItem("idioma", "english");
        commit('UPDATE_IDIOMA');
    },
    setTokenSpanish({ commit, state }, payload) {
        state.languageModal = false; 
        localStorage.removeItem("idioma");
        localStorage.setItem("idioma", "spanish");
        commit('UPDATE_IDIOMA');
    },
      setData({ commit }, data) {
        commit('REMOVE_DATA');
        console.log("setDATA ACTION: ESTE ES EL USUARIO Y EL TOKEN",data.token," ",data.usuario)
        commit('SET_DATA', data);
      },
      cerrarSesion(){
        localStorage.removeItem("token")
        localStorage.removeItem("usuario")
        window.location.href="https://raptoreumworld.com/"
      },
  
  },
  modules: {
  }
})