import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
let routes =[
  {
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
 // {
 //   path: '/about',
 //   name: 'about',
 //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
 // },
  {
    path: '/ingresar',
    name: 'ingresar',
    component: () => import(/* webpackChunkName: "about" */ '../views/ingresar.vue')
  },
  {
    path: '/whitepaper',
    name: 'whitepaper',
    component: () => import(/* webpackChunkName: "about" */ '../views/whitepaper.vue')
  },
  {
    path: '/nftmarket',
    name: 'a',
    component: () => import(/* webpackChunkName: "about" */ '../views/nftmarket.vue')
  },
  {
    path: '/transactions',
    name: 'assets',
    component: () => import(/* webpackChunkName: "about" */ '../views/rwcTransactions.vue')
  },
  {
    path: '/registrarse',
    name: 'rgstr',
    component: () => import(/* webpackChunkName: "about" */ '../views/registrarse.vue')
  },
  {
    path: '/contratos',
    name: 'cursos',
    component: () => import(/* webpackChunkName: "about" */ '../views/contratos.vue')
  },
  {
    path: '/assetsmarket',
    name: 'assetsmarket',
    component: () => import(/* webpackChunkName: "about" */ '../views/assetsMarket.vue')
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import(/* webpackChunkName: "about" */ '../views/policy.vue')
  },
  {
    path: '/changePassword',
    name: 'changePass',
    component: () => import(/* webpackChunkName: "about" */ '../views/changePass.vue')
  },
  {
    path: '/termsandconditions',
    name: 'termsandconditions',
    component: () => import(/* webpackChunkName: "about" */ '../views/termsandconditions.vue')
  },
  {
    path: '/raptoreumsociety',
    name: 'raptosociety',
    component: () => import(/* webpackChunkName: "about" */ '../views/raptoreumSociety.vue')
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
//despues se hacer esto, importar 
export default router
