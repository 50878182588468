<template>
   <withdrawModal v-if='$store.state.withdrawModalClicked == true &&  !$store.state.languageModal == true && !$store.state.sessionExpired == true &&  !$store.state.cargando == true'></withdrawModal>
   <modalTOTP  v-if='$store.state.modalTOTP.open == true &&  !$store.state.languageModal == true && !$store.state.sessionExpired == true &&  !$store.state.cargando == true'></modalTOTP>
   <createAssetModal  v-if='$store.state.createAssetModalClicked == true &&  !$store.state.languageModal == true && !$store.state.sessionExpired == true &&  !$store.state.cargando == true'></createAssetModal>
  <registerAssetModal   v-if='$store.state.registerAssetModal== true &&  !$store.state.languageModal == true && !$store.state.sessionExpired == true &&  !$store.state.cargando == true'> </registerAssetModal>
  <createNftModal  v-if='$store.state.registerNftModal== true &&  !$store.state.languageModal == true && !$store.state.sessionExpired == true &&  !$store.state.cargando == true'> ></createNftModal>
   <venderAssetModal v-if='$store.state.modalVenderToken == true &&  !$store.state.languageModal == true && !$store.state.sessionExpired == true &&  !$store.state.cargando == true'></venderAssetModal>
  <div class="locationDiv" v-if="$store.state.idioma  && $store.state.idioma == 'spanish'&& !$store.state.sessionExpired == true &&   !$store.state.cargando == true&&  !$store.state.languageModal == true" ><h1 >Inicio</h1></div>
  <div class="locationDiv" v-if="$store.state.idioma  && $store.state.idioma == 'english'&& !$store.state.sessionExpired == true && !$store.state.cargando == true&&  !$store.state.languageModal == true" ><h1 >Home</h1></div>
  <div id="homeWrapperNotLogged" v-if="!$store.state.idioma == false && $store.state.idioma == 'spanish' && $store.state.cargando==false && !$store.state.sessionExpired == true &&  !$store.state.languageModal == true && !$store.state.cargando == true">
   

    <div class="wrapper" v-if="$store.state.logged==false ">  

    
      <div class="main">
 
 
        <h1 v-if="$store.state.logged==false " style="font-size: small;padding-top: 2vh;padding-bottom: 1vh">RaptoreumWorld: una solución para aquellos que desean <i style="color:#00E4FF">crear activos en Raptoreum con IPFS</i> y <i style="color:#FF8700">venderlos eficazmente</i></h1>



<div class="accordion" id="accordionExample">
<div class="accordion-item">
<h2 class="accordion-header">
<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
  Crea assets con IPFS
</button>
</h2>
<div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
<div class="accordion-body">
  <img src="../assets/create.jpg"   class="img-accordion" alt="...">

  <p>Crea increibles NFTs y monedas con la mejor tecnologia. Crear assets con nosotros te da ventajas sobre  quienes registren assets en raptoreumworld creados externamente.</p>
</div>
</div>
</div>
<div class="accordion-item">
<h2 class="accordion-header">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
  Vende  y compra assets
</button>
</h2>
<div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
<div class="accordion-body">
  <img src="../assets/sell.jpg" class="img-accordion" alt="...">
  <p>Nuestro metodo de transacciones eficaz minimiza los riesgos de transacciones fraudulentas o fraude. </p>
</div>
</div>
</div>

</div>














<div id="carouselExampleDark" class="carousel carousel-dark slide">
<div class="carousel-indicators">
<button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
<button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
<button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
</div>
<div class="carousel-inner">
<div class="carousel-item active" data-bs-interval="10000">
<img src="../assets/create2.jpg" class="d-block img-carousel" alt="...">
<div class="carousel-caption d-none d-md-block">
  <h5>Crea assets con IPFS</h5>
  <p>Crea increibles NFTs y monedas con la mejor tecnologia. Crear assets con nosotros te da ventajas sobre  quienes registren assets en raptoreumworld creados externamente.</p>
</div>
</div>
<div class="carousel-item" data-bs-interval="2000">
<img src="../assets/sell2.jpg" class="d-block img-carousel" alt="...">
<div class="carousel-caption d-none d-md-block">
  <h5>Vende y compra assets</h5>
  <p>Nuestro metodo de transacciones eficaz minimiza los riesgos de transacciones fraudulentas o fraude. </p>
</div>
</div>

</div>
<button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
<span class="carousel-control-prev-icon" aria-hidden="true"></span>
<span class="visually-hidden">Previous</span>
</button>
<button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
<span class="carousel-control-next-icon" aria-hidden="true"></span>
<span class="visually-hidden">Next</span>
</button>
</div>









 
 
      </div>
      



  
      </div>
      <div class="wrapper" v-if="$store.state.logged==true">  
        <div :class="{'container-wrapper':!$store.state.busy.status,'container-wrapper-busy':$store.state.busy.status}"> 
          <div class="spinner-border text-info" role="status" v-if='$store.state.busy.status'>
  <span class="visually-hidden">Loading...</span>
</div>
<div style="height:min-content;width:100%"  v-if="$store.state.busy.reasons && $store.state.busy.reasons.length>0">

  <p style="color:#0ff">
    Tu cuenta está ocupada vendiendo/comprando o creando un activo. Todas las funciones de tu cuenta están bloqueadas excepto la visualización de tu saldo y transacciones.
  </p>
</div>
               <p v-if="!$store.state.busy.status" style="color:#49FF00"><i v-if="!$store.state.busy.status"  style="color:#49FF00">Estado: Todas las funciones disponibles &#10003;</i></p>
          <h1 v-on:click="getTOTP()" v-if="$store.state.totp==false" style="color:#f00;font-size: small;">No tienes 2FA activado. haz click aqui para activarlo y mantener tu cuenta segura.</h1>

   
        <div class="assetsRegisteredOptions">  
           
       
          <div 
  v-on:click="setTab('balance')" 
  :style="balance ? activeStyle : inactiveStyle"
  style="width:33.33%;height:100%;">
  <h2 style="font-size:12px;font-weight: bold;">Balance</h2>
</div>

<div 
  v-on:click="setTab('transactions')" 
  :style="!balance && !creations ? activeStyle : inactiveStyle"
  style="width:33.33%;height:100%;">
  <h2 v-if="transactions" style="font-size:12px;font-weight: bold;">Transacciones &#8634;</h2>
  <h2 v-if="!transactions" style="font-size:12px;font-weight: bold;">Transacciones </h2>
</div>

<div 
  v-on:click="setTab('creations')" 
  :style="creations ? activeStyle : inactiveStyle"
  style="width:33.33%;height:100%;">
  <h2 v-if="creations" style="font-size:12px;font-weight: bold;">Creaciones &#8634;</h2>
  <h2 v-if="!creations" style="font-size:12px;font-weight: bold;">Creaciones </h2>
</div>
         </div> 
         <div style="border: 2px solid black ;" class="container-raptoreum-data"  v-if="balance">
          <div class="container-balance">
            <div class="spinner-border" role="status" v-if="balanceCargandoSpin">
               <span class="visually-hidden">Loading...</span>
              </div> 
            <h1  v-if="!balanceCargandoSpin"><img class="balance-img" src="../assets/raptoreum-balance.png" alt="">Raptoreum total: {{ $store.state.rtmBalance }}</h1>
            <input  v-if="!balanceCargandoSpin" type="button" v-on:click="getBalance()" value="⟳">
            </div>
      
       

            <div class="container-address">
              <h2>deposit:</h2>
              <p v-if="$store.state.wallet.raptoreum!='none'">{{$store.state.wallet.raptoreum}}   <button @click="copiarTexto($store.state.wallet.raptoreum)">copy📃</button></p>         
              <input type='button'  v-if="$store.state.wallet.raptoreum=='none' || $store.state.wallet.raptoreum==null"  v-on:click="createWallet" value="Create wallet">
              <input  v-if="$store.state.wallet.raptoreum!='none'" type="button" v-on:click="openWithdrawModal"  value="Retirar">
            </div> 
        </div>   

        <div  class="container-transactions-data" v-if="transactions">
              <div class="spinner-border" role="status" v-if="transactions != 'noTransactions' && transactions.length == 0">
               <span class="visually-hidden">Loading...</span>
              </div> 
              <h1 v-if="transactions =='noTransactions'">Compra o vende tu primer token para ver transacciones aquí</h1>
              <div class="container-transactions"  v-if="transactions != 'noTransactions' && transactions.length!=0" v-for="obj in transactions">
                <div  v-if="obj.type == 'venta'"  class="transaction">
                  <h1>venta  <p style="font-size: medium;">(status: {{ obj.status }})</p></h1>   <h4 style="color: #0f0;">+{{obj.rtmGanado}}</h4>   <img class="balance-img" src="../assets/raptoreum-balance.png" alt=""> <img v-if="obj.typeAsset==='Asset'"   class="balance-img" :src="obj.URLcoinSoldOrBought"> <img v-if="obj.typeAsset==='nft'"   class="balance-nft" :src="obj.URLcoinSoldOrBought"> <h4  style="color: #f00;">-{{ obj.assetVendidoCantidad}}</h4> <p>( {{getDate(obj.date)}})</p>
                  <div class="assetsOptions">             
                      <input type="button" v-on:click="redirectToPage(obj.txid)"  value="see more">
                  </div>
                </div>

                <div  v-if="obj.type == 'compra'"  class="transaction">
                  <h1> compra <p style="font-size: medium;">(status: {{obj.status }})</p></h1> <h4  style="color: #f00;"> -{{obj.rtmGastado}}</h4>  <img class="balance-img" src="../assets/raptoreum-balance.png" alt="">    <img v-if="obj.typeAsset==='Asset'"   class="balance-img" :src="obj.URLcoinSoldOrBought"> <img v-if="obj.typeAsset==='nft'"   class="balance-nft" :src="obj.URLcoinSoldOrBought">  <h4  style="color: #0f0;">+{{ obj.assetCompradoCantidad}}</h4>   <p>( {{getDate(obj.date)}})</p>
                  <div class="assetsOptions" >             
                 <input   type="button" v-on:click="redirectToPage(obj.txid)"  value="see more">
              </div>

                </div>
             
                 
            </div>  
         </div>
         <div  class="container-transactions-data" v-if="creations"> 
          <h1 v-if="pendingCreations =='none'">No hay creaciones recientes</h1>
          <div class="container-transactions"  v-if="pendingCreations != 'none' && pendingCreations.length!=0" v-for="obj in pendingCreations">
            <div  class="creation">
                   <h3 style="font-size:small">asset-name: <i>{{obj.assetName}}</i></h3>
                   <h3 style="font-size:small" v-if="obj.status.startsWith('txid')">status:<i> asset created but not minted (100 raptoreums were not collected. To proceed with the mint attempt, 100 raptoreums are required.). </i></h3>  
                   <input style="font-size:small" v-if="obj.status.startsWith('txid')" type="button" value="mint asset" v-on:click="mintAsset(obj.status)">
                   <h3 style="font-size:small" v-if="!obj.status.startsWith('txid')">status: <i>{{obj.status}} </i></h3>       
            </div>
          </div>
         </div>
        
  
       
            <div class="cointainer-logged"><h1>tus assets:</h1>
              <input type="button" style="color:#f00," v-on:click="openCreateNftModal" value="crea tu NFT aquí">
              <input type="button" style="color:#f00," v-on:click="openCreateAssetModal" value="crea tu moneda aquí"></div>


              <div class="assetsRegisteredOptions">      
           <div 
   v-on:click="setTab('assets')" 
   :style="!registeredAssets ? activeStyle : inactiveStyle"
   style="width:50%;height:100%;">
   <h2 style="font-size:12px;font-weight: bold;">mis assets</h2>
 </div>
 <div 
   v-on:click="setTab('registeredAssets')" 
   :style="registeredAssets ? activeStyle : inactiveStyle"
   style="width:50%;height:100%;">
   <h2 style="font-size:12px;font-weight: bold;"> assets no registrados</h2>
 </div>
          </div> 

      
          <div class="tokens-wrapper" v-if="!registeredAssets">
            <h3  v-if="$store.state.totalAssets=='no hay assets del usuario'">no se encontraron activos.</h3>
            <div class="container-tokens"  v-if="$store.state.totalAssets!='no hay assets del usuario'" v-for="asset in $store.state.totalAssets">
              <img v-if="asset.type==='TOKEN'" :src="asset.assetpicture" class="assetpic" alt="">      <div class="card" v-if="asset.type==='NFT'" style="height:50px;"  :key="index"><img :src="asset.assetpicture" style="height:50px;" alt="Uploaded Image"></div>   <h4>{{ asset.asset }}</h4>  <h4 v-if="asset.type=='TOKEN'">({{ asset.acronimo }}):</h4>   <h4>{{ asset. balance}}</h4> <h4 v-if="asset.type=='TOKEN'">type: <i>coin</i></h4> <h4 v-if="asset.type=='NFT'">type: <i>nft</i></h4>
              <div class="assetsOptions">             
                <input   v-if="!asset.enVenta&& asset.balance>0" type="button" v-on:click="vender({asset:asset.asset,balance:asset.balance,assetpicture:asset.assetpicture,assetId:asset.assetId,acronimo:asset.acronimo})"  value="vender">
                 <input   v-if="!asset.enVenta && asset.balance>0" type="button" v-on:click="openWithdrawModal(asset.asset)"  value="retirar">
                 <input   v-if="!asset.enVenta && asset.balance==0" type="button" v-on:click="searchToken(asset.asset)"  value="comprar">
                 <input   v-if="asset.enVenta==true " type="button" v-on:click="detenerVenta(asset._id,asset.asset)"  value="detener venta">
              </div>
            </div>         
          </div>  

          <div class="tokens-wrapper" v-if="registeredAssets">
         <p>   <i>nota: solo puedes registrar tokens (not unique assets). los NFT (unique assets) no se pueden registrar en raptoreumworld para evitar NFTS maliciosos.
               <i style="color:#f00">si depositaste un NFT, puedes retirarlo libremente.</i>
            </i>
          </p>
            <h3  v-if="$store.state.notRegisteredAssets=='empty'">Deposita un asset no registrado en raptoreumworld y lo verás aquí.</h3>
            <div class="container-tokens"  v-if="$store.state.notRegisteredAssets !=='empty'"  v-for="asset in $store.state.notRegisteredAssets">
              <img src="https://res.cloudinary.com/debvhmcid/image/upload/v1710279820/rodrigoelmejor-raptoreumworld.png" class="assetpic" alt="">   <h4>{{ asset.asset }}</h4>    <h4>{{ asset. balance}}</h4>   <h4>{{ "TYPE:"+" "+asset.type}}</h4> 
              <div class="assetsOptions">             
                <input v-if="asset.type=='NFT'" type="button" v-on:click="openWithdrawModal(asset.asset)"  value="retirar">
                 <input v-if="asset.type=='TOKEN'" type="button" v-on:click="openRegistrar(asset.asset)"  value="registrar">
              </div>
            </div>         
          </div>  
        </div>
  
      </div>
     
      <div  class="footer"><a  href="https://discord.gg/98VRTk33Dx" style="color:#fff">¡Unete a nuestro discord aquí y usa nuestro bot!</a></div>
  </div>

  <div id="homeWrapperNotLogged" v-if="!$store.state.idioma == false && $store.state.idioma == 'english' && $store.state.cargando==false && !$store.state.sessionExpired == true &&  !$store.state.languageModal == true && !$store.state.cargando == true">
       

    <div class="wrapper" v-if="$store.state.logged==false ">  

 <div class="main">
 
  <h1  v-if="$store.state.logged==false " style="font-size: small;padding-top: 2vh;padding-bottom: 1vh">RaptoreumWorld: a solution for those who want to <i style="color:#00E4FF">create Raptoreum assets con IPFS</i> and <i style="color:#FF8700">sell them effectively</i></h1>

  <div class="accordion" id="accordionExample">
<div class="accordion-item">
<h2 class="accordion-header">
<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
  Create assets with IPFS
</button>
</h2>
<div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
<div class="accordion-body">
  <img src="../assets/create.jpg"   class="img-accordion" alt="...">

  <p>Create amazing NFTs and coins with the best technology. Creating assets with us gives you advantages over those who register externally created assets on RaptoreumWorld.</p>
</div>
</div>
</div>
<div class="accordion-item">
<h2 class="accordion-header">
<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
  Sell and buy assets
</button>
</h2>
<div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
<div class="accordion-body">
  <img src="../assets/sell.jpg" class="img-accordion" alt="...">
  <p>Our efficient transaction method minimizes the risks of fraudulent transactions or fraud.</p>

</div>
</div>
</div>

</div>








<div id="carouselExampleDark" class="carousel carousel-dark slide">
<div class="carousel-indicators">
<button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
<button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
<button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
</div>
<div class="carousel-inner">
<div class="carousel-item active" data-bs-interval="10000">
<img src="../assets/create2.jpg" class="d-block img-carousel" alt="...">
<div class="carousel-caption d-none d-md-block">
  <h5>Create Raptoreum assets with IPFS</h5>
  <p>Create amazing NFTs and coins with the best technology. Creating assets with us gives you advantages over those who register assets on RaptoreumWorld that were created externally.</p>
</div>
</div>
<div class="carousel-item" data-bs-interval="2000">
<img src="../assets/sell2.jpg" class="d-block img-carousel" alt="...">
<div class="carousel-caption d-none d-md-block">
  <h5>Sell and Buy Assets</h5>
<p>Our efficient transaction method minimizes the risks of fraudulent transactions or fraud.</p>

</div>
</div>

</div>
<button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
<span class="carousel-control-prev-icon" aria-hidden="true"></span>
<span class="visually-hidden">Previous</span>
</button>
<button class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
<span class="carousel-control-next-icon" aria-hidden="true"></span>
<span class="visually-hidden">Next</span>
</button>
</div>




 </div>











  
      </div>
      
      <div class="wrapper" v-if="$store.state.logged==true">  
        <div :class="{'container-wrapper':!$store.state.busy.status,'container-wrapper-busy':$store.state.busy.status}"> 
          <div class="spinner-border text-info" role="status" v-if='$store.state.busy.status'>
  <span class="visually-hidden">Loading...</span>
</div>
<p v-if="!$store.state.busy.status" style="color:#49FF00"><i v-if="!$store.state.busy.status"  style="color:#49FF00">Status: all functions avaible &#10003;</i></p>
<div style="height:min-content;width:100%" v-if="$store.state.busy.reasons && $store.state.busy.reasons.length>0" >
      <p style="color:#0ff">Your account is busy selling/buying or creating an asset. All functions of your account are blocked except for viewing your balance and transactions.</p>
</div>

       <h1 v-on:click="getTOTP()" v-if="$store.state.totp==false" style="color:#f00;font-size: small;">Your account doesn't have 2FA activated. click here to activate 2FA and keep your account secure.</h1>
    

        <div class="assetsRegisteredOptions">        
          <div 
  v-on:click="setTab('balance')" 
  :style="balance ? activeStyle : inactiveStyle"
  style="width:33.33%;height:100%;">
  <h2 style="font-size:12px;font-weight: bold;">Balance</h2>
</div>

<div 
  v-on:click="setTab('transactions')" 
  :style="!balance && !creations ? activeStyle : inactiveStyle"
  style="width:33.33%;height:100%;">
  <h2 v-if="transactions" style="font-size:12px;font-weight: bold;">Transactions &#8634;</h2>
  <h2 v-if="!transactions" style="font-size:12px;font-weight: bold;">Transactions </h2>
</div>

<div 
  v-on:click="setTab('creations')" 
  :style="creations ? activeStyle : inactiveStyle"
  style="width:33.33%;height:100%;">
  <h2 v-if="creations" style="font-size:12px;font-weight: bold;">Creations &#8634;</h2>
  <h2 v-if="!creations" style="font-size:12px;font-weight: bold;">Creations </h2>
</div>
         </div> 
       <div style="border: 2px solid black ;" class="container-raptoreum-data"  v-if="balance">
          <div class="container-balance">
            <div class="spinner-border" role="status" v-if="balanceCargandoSpin">
               <span class="visually-hidden">Loading...</span>
              </div> 
            <h1  v-if="!balanceCargandoSpin"><img class="balance-img" src="../assets/raptoreum-balance.png" alt="">Raptoreum total: {{ $store.state.rtmBalance }}</h1>
            <input  v-if="!balanceCargandoSpin" type="button" v-on:click="getBalance()" value="⟳">
            </div>
      
       

            <div class="container-address">
              <h2>deposit:</h2>
              <p v-if="$store.state.wallet.raptoreum!='none'">{{$store.state.wallet.raptoreum}}   <button @click="copiarTexto($store.state.wallet.raptoreum)">copy📃</button></p>         
              <input type='button'  v-if="$store.state.wallet.raptoreum=='none' || $store.state.wallet.raptoreum==null"  v-on:click="createWallet" value="crear billetera">
              <input  v-if="$store.state.wallet.raptoreum!='none'" type="button" v-on:click="openWithdrawModal"  value="Withdraw">
            </div> 
        </div>  

          
        <div  class="container-transactions-data" v-if="transactions">
              <div class="spinner-border" role="status" v-if="transactions != 'noTransactions' && transactions.length == 0">
               <span class="visually-hidden">Loading...</span>
              </div> 
              <h1 v-if="transactions =='noTransactions'">Buy or sell your first asset to see transactions here</h1>
              <div class="container-transactions"  v-if="transactions != 'noTransactions' && transactions.length!=0" v-for="obj in transactions">
                <div  v-if="obj.type == 'venta'"  class="transaction">
                  <h1>sale  <p style="font-size: medium;">(status: {{ obj.status }})</p></h1>   <h4 style="color: #0f0;">+{{obj.rtmGanado}}</h4>   <img class="balance-img" src="../assets/raptoreum-balance.png" alt=""> <img v-if="obj.typeAsset==='Asset'"   class="balance-img" :src="obj.URLcoinSoldOrBought"> <img v-if="obj.typeAsset==='nft'"   class="balance-nft" :src="obj.URLcoinSoldOrBought"> <h4  style="color: #f00;">-{{ obj.assetVendidoCantidad}}</h4> <p>( {{getDate(obj.date)}})</p>
                  <div class="assetsOptions">             
                      <input type="button" v-on:click="redirectToPage(obj.txid)"  value="see more">
                  </div>
                </div>

                <div  v-if="obj.type == 'compra'"  class="transaction">
                  <h1> purchase <p style="font-size: medium;">(status: {{obj.status }})</p></h1> <h4  style="color: #f00;"> -{{obj.rtmGastado}}</h4>  <img class="balance-img" src="../assets/raptoreum-balance.png" alt="">    <img v-if="obj.typeAsset==='Asset'"   class="balance-img" :src="obj.URLcoinSoldOrBought"> <img v-if="obj.typeAsset==='nft'"   class="balance-nft" :src="obj.URLcoinSoldOrBought">  <h4  style="color: #0f0;">+{{ obj.assetCompradoCantidad}}</h4>   <p>( {{getDate(obj.date)}})</p>
                  <div class="assetsOptions" >             
                 <input   type="button" v-on:click="redirectToPage(obj.txid)"  value="see more">
              </div>

                </div>
             
                 
            </div>  
         </div>
         <div  class="container-transactions-data" v-if="creations"> 
          <h1 v-if="pendingCreations =='none'">no recent creations found</h1>
          <div class="container-transactions"  v-if="pendingCreations != 'none' && pendingCreations.length!=0" v-for="obj in pendingCreations">
            <div  class="creation">
                   <h3 style="font-size:small">asset-name: <i>{{obj.assetName}}</i></h3>
                   <h3 style="font-size:small" v-if="obj.status.startsWith('txid')">status:<i> asset created but not minted (100 raptoreums were not collected. To proceed with the mint attempt, 100 raptoreums are required.). </i></h3>  
                   <input style="font-size:small" v-if="obj.status.startsWith('txid')" type="button" value="mint asset" v-on:click="mintAsset(obj.status,obj.assetName)">
                   <h3 style="font-size:small" v-if="!obj.status.startsWith('txid')">status: <i>{{obj.status}} </i></h3>       
            </div>
          </div>
         </div> 
        
       
            <div class="cointainer-logged"><h1>your assets:</h1>
              <input type="button" style="color:#f00," v-on:click="openCreateNftModal" value="create your NFT here">
              <input type="button" style="color:#f00," v-on:click="openCreateAssetModal" value="create your coin here"></div>



              <div class="assetsRegisteredOptions">      
           <div 
   v-on:click="setTab('assets')" 
   :style="!registeredAssets ? activeStyle : inactiveStyle"
   style="width:50%;height:100%;">
   <h2 style="font-size:12px;font-weight: bold;">my assets</h2>
 </div>
 <div 
   v-on:click="setTab('registeredAssets')" 
   :style="registeredAssets ? activeStyle : inactiveStyle"
   style="width:50%;height:100%;">
   <h2 style="font-size:12px;font-weight: bold;">not registered assets</h2>
 </div>
          </div> 
        
      
          <div class="tokens-wrapper" v-if="!registeredAssets">
            <h3  v-if="$store.state.totalAssets=='no hay assets del usuario'">no assets found.</h3>
            <div class="container-tokens"  v-if="$store.state.totalAssets!='no hay assets del usuario'" v-for="asset in $store.state.totalAssets">
              <img v-if="asset.type==='TOKEN'" :src="asset.assetpicture" class="assetpic" alt="">      <div class="card" v-if="asset.type==='NFT'" style="height:50px;"  :key="index"><img :src="asset.assetpicture" style="height:50px;" alt="Uploaded Image"></div>   <h4>{{ asset.asset }}</h4>  <h4 v-if="asset.type=='TOKEN'">({{ asset.acronimo }}):</h4>   <h4>{{ asset. balance}}</h4> <h4 v-if="asset.type=='TOKEN'">type: <i>coin</i></h4> <h4 v-if="asset.type=='NFT'">type: <i>nft</i></h4>
              <div class="assetsOptions">             
                <input   v-if="!asset.enVenta&& asset.balance>0" type="button" v-on:click="vender({asset:asset.asset,balance:asset.balance,assetpicture:asset.assetpicture,assetId:asset.assetId,acronimo:asset.acronimo})"  value="sell">
                 <input   v-if="!asset.enVenta && asset.balance>0" type="button" v-on:click="openWithdrawModal(asset.asset)"  value="withdraw">
                 <input   v-if="!asset.enVenta && asset.balance==0" type="button" v-on:click="searchToken(asset.asset)"  value="buy">
                 <input   v-if="asset.enVenta==true " type="button" v-on:click="detenerVenta(asset._id,asset.asset)"  value="stop sale">
              </div>
            </div>         
          </div>  

          <div class="tokens-wrapper" v-if="registeredAssets">
       <p>     <i>Note: You can only register tokens (non-unique assets). NFTs (unique assets) cannot be registered on RaptoreumWorld to prevent malicious NFTs.
   <i style="color:#f00">If you deposited an NFT, you can withdraw it freely.</i>
</i>
</p>  
            <h3  v-if="$store.state.notRegisteredAssets=='empty'">Deposit any asset not created in RaptoreumWorld & you'll see it here.</h3>
            <div class="container-tokens"  v-if="$store.state.notRegisteredAssets !=='empty'"  v-for="asset in $store.state.notRegisteredAssets">
              <img src="https://res.cloudinary.com/debvhmcid/image/upload/v1710279820/rodrigoelmejor-raptoreumworld.png" class="assetpic" alt="">   <h4>{{ asset.asset }}</h4>   <h4>{{ asset. balance}}</h4>   <h4>{{ "TYPE:"+" "+asset.type}}</h4> 
              <div class="assetsOptions">             
                 <input v-if="asset.type=='NFT'" type="button" v-on:click="openWithdrawModal(asset.asset)"  value="withdraw">
                 <input v-if="asset.type=='TOKEN'" type="button" v-on:click="openRegistrar(asset.asset)"  value="register">
              </div>
            </div>         
          </div>  
        </div>
  
      </div>
     
      <div  class="footer"><a  href="https://discord.gg/98VRTk33Dx" style="color:#fff">join our discord here & try out our RaptoreumWorld bot too!</a></div>
  </div>
 </template>
     
     <script>
     // @ is an alias to /src
     import {io} from 'socket.io-client'
     import store from '../store/index'
     import axios from 'axios'
     import withdrawModal from '../../src/components/withdrawModal.vue'
     import venderAssetModal from '../../src/components/venderAssetModal.vue'
     import createAssetModal from '@/components/createAssetModal.vue'
     import createNftModal from '@/components/createNftModal.vue'
     import registerAssetModal from '@/components/registerAssetModal.vue'
     import modalTOTP from '@/components/modalTOTP.vue'
     export default {
       name: 'HomeView',
       components: {
        withdrawModal,createAssetModal,venderAssetModal,registerAssetModal,createNftModal,modalTOTP
       },data(){
         return{
           message: "el socket no ha funcionado",
           transactions:[], 
           balanceCargando:false,
           registeredAssets:false,
           personas:[],
           balanceCargandoSpin:false,
           balance:true,
           creations:false,
           transactions:false,
           rtmBalance:null,
           rtmAddress:"",
           wallet:{rtmworld:null,raptoreum:null},
           activeStyle: {
      color: '#000',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'linear-gradient(to top, #666, white)'
    },
    inactiveStyle: {
      color: '#fff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#000'
    },pendingCreations:"none"
         }
       },
     async  mounted() {
      this.socket = io('https://backend-raptoreum.world/', {
    withCredentials: false,
    query: {
        "key": "skrillex",
        "object": JSON.stringify({ token: localStorage.getItem("token"),subject:"balance" })
    }
});
this.socket.on("errorStoppingSell",()=>
    {
      alert("error trying to stop sell :/ ERR: ERROR INTENTANDO PARAR LA VENTA")
    })
    this.socket.on("expired",()=>
    {
      this.$store.state.sessionExpired=true
    })
this.socket.on('ventaUsuarioDetenida',(data)=>
    {    
      let foundIndex = this.$store.state.totalAssets.findIndex(e => e._id === data);
   if (foundIndex !== -1) 
      {   
       const { enVenta, _id, ...rest } = this.$store.state.totalAssets[foundIndex];
       const updatedAsset = { ...rest};
       this.$store.state.totalAssets[foundIndex] = updatedAsset;
      }
      alert("Sale successfully stopped SUCCESS: VENTA DETENIDA CORRECTAMENTE")   
    })
    this.socket.on("notSelling",()=>{ 
      alert("asset not in market. ERR: EL ASSET NO ESTÁ EN EL MARKET")
    })
    
    this.socket.on("notBusySeller", (data) => { 
      let assets=this.$store.state.totalAssets
  
      console.log("llegó not busy seller:",data)
    let isSelling=  assets.find(i=>i._id===data.ventaId)
    if(isSelling)
    {
      if (data) 
      {
        this.$store.state.busy.reasons = this.$store.state.busy.reasons.filter(element => element.reason !== "sale");
        if (this.$store.state.busy.reasons.length === 0) {
          this.$store.state.busy.status = false;
        }else{
          this.$store.state.busy.status = true;
   
      }
   }
   if(this.$store.state.usuario===data.buyer){
      this.$store.state.busy.reasons = this.$store.state.busy.reasons.filter(element => element.reason !== "assetCreation");
      if(this.$store.state.busy.reasons.length === 0){
        this.$store.state.busy.status = false;
      }else{
        this.$store.state.busy.status = true;
      }
   }
   }});
   this.socket.on("busySeller", (data) => { 
    let assets=this.$store.state.totalAssets


  
    let isSelling= assets.find(i=>i._id===data.ventaId)
    if(isSelling)
    {
      if (data) 
      {
        this.$store.state.busy.reasons = this.$store.state.busy.reasons.filter(element => element.reason !== "sale");
        this.$store.state.busy.reasons = this.$store.state.busy.reasons.filter(element => element.reason !== "assetCreation");
        let busyAsset= false
        if(data.reason==="assetCreation")busyAsset=true
        if(busyAsset){
          this.$store.state.busy.reasons.push({reason:"assetCreation"})
        }
        
        let busySale=false
        if(data.reason==="sale")busySale=true
        if(busySale){
          this.$store.state.busy.reasons.push({reason:"sale"})
        }
        if (this.$store.state.busy.reasons.length > 0)
        {
          this.$store.state.busy.status = true;
        }
      }
   }
   if(this.$store.state.usuario===data.buyer){
    this.$store.state.busy.reasons = this.$store.state.busy.reasons.filter(element => element.reason !== "sale");
        this.$store.state.busy.reasons.push({reason:"sale"})
        if (this.$store.state.busy.reasons.length > 0)
        {
          this.$store.state.busy.status = true;
        }
   }
  
   });
    this.socket.on("getBusyUserData",(data)=>{ 
      console.log("LLEGÓ GETBUSYUSERDATA:",data)
     if(data && data.length > 0){
      this.$store.state.busy.reasons = this.$store.state.busy.reasons.filter(element => element.reason !== "sale");
        this.$store.state.busy.reasons = this.$store.state.busy.reasons.filter(element => element.reason !== "assetCreation");
       this.$store.state.busy.status=true
       let busyAsset=data.find(i=>i.reason==="assetCreation")
        if(busyAsset){
          this.$store.state.busy.reasons.push({reason:"assetCreation"})
        }
        let busySale=data.find(i=>i.reason==="sale")
        if(busySale){
          this.$store.state.busy.reasons.push({reason:"sale"})
        }
     }
    })
      await    this.getBalance()
      await    this.socket.emit("getBusyUser",{token:localStorage.getItem("token")})
      
      const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get('type');
  if(type && type==="transactions"){
   this.setTab('transactions')
  }else if(type && type==="creations"){
    this.setTab('creations')
  }
       },
     destroyed(){
        this.socket.disconnect()
      },
     methods:{
      //arreglar esto en concordancia con el front.
      setTab(tab) {
    if (tab == 'balance') {
      this.balance = true;
      this.creations = false;
      this.transactions = false; // Asegurarse de que solo balance esté activo
    } else if (tab == 'transactions') {
      this.balance = false;
      this.creations = false;
      this.transactions = true; // Activar transacciones
      this.getTransactions();
    } else if (tab == 'creations') {
      
      this.balance = false;
      this.creations = true;
      console.log("creations es true")
      this.transactions = false; // Activar creaciones
      this.getCreations();
    } else if (tab == 'assets') {
      
      this.registeredAssets = false;
  
    }else if (tab == 'registeredAssets') {
      
      this.registeredAssets = true;
  
    }
  
  },
      goTo(to){
        this.$router.push(to)
      },
      mintAsset(status,asset) {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
  };
  let txid = status.split(':')[1] || '';
  axios.post('https://backend-raptoreum.world/app2/mint', { txid ,asset}, config)
    .then((response) => {
      this.pendingCreations.forEach(i => {
        if (i.status.startsWith('txid')) {
          let txidFound = i.status.split(':')[1] || '';
          if (txidFound === txid) {
            i.status = "SUCCESS: asset created successfully!";
          }
        }
      });
      alert("minted correctly! SUCCESS: ASSET MINTEADO CORRECTAMENTE!");
      return;
    })
    .catch((error) => {
      console.log(error);
      if (error.response && error.response.status === 429) {
        alert("wait 20 seconds before trying again  SPANISH SUB:  espera 20 segundos antes de hacer otro intento");
      } else {
        alert("couldn't mint ERR: NO PUDIMOS MINTEAR");
      }
    });
},
      verifySession(){
      //hacer modal y sacarlo cuando todo termine de cargar 
      console.log("EL TOKEN QUE VAMOS A ENVIAR!", localStorage.getItem("token"))
      axios.post("https://backend-raptoreum.world/app2/verify", {
        token: localStorage.getItem("token")})
      .then((res)=>{
        this.$store.dispatch("setData", {token:res.data.token,usuario:res.data.usuario});
        this.$store.state.cargando=false
      }).catch(err=>
        {

          if(err.response.data){
            if(err.response.data == "expired" ){
              this.$store.state.sessionExpired=true
            }else if(err.response.data == "notLogged"){
                localStorage.removeItem("token")
                localStorage.removeItem("usuario")
            }
          }
          this.$store.state.cargando=false
        })
    },
   
      createWallet(){       
        axios.post('https://backend-raptoreum.world/app2/crearWallet', { token: localStorage.getItem("token") })
        .then(response => {
     
         alert("Wallet successfully created :] SUCCESS: CREASTE TU WALLET CON EXITO")
      
          this.$store.state.wallet.raptoreum=response.data.address
          this.verifySession()
        })
        .catch(error => {
      alert("there was a problem creating the wallet :c ERR: NO SE PUDO CREAR LA WALLET")
        });
      },
    copiarTexto(texto) 
    {
    // Crea un elemento de texto temporal
    const input = document.createElement('textarea');
    input.value = texto;
    document.body.appendChild(input);
    // Selecciona y copia el texto al portapapeles
    input.select();
    document.execCommand('copy');
    // Elimina el elemento temporal
    document.body.removeChild(input);
    // Muestra una notificación o mensaje de éxito
    alert('¡copied!');
  },getTransactions()
  {
    this.balance=false
    const config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
};
    axios.get('https://backend-raptoreum.world/app2/transactions', config)
      .then((response) => { 
        console.log(response)
        if(response.data == "noTransactions"){
         this.transactions="noTransactions"
        }else if (response.data.length >0){

          this.transactions=response.data
        }
      })
      .catch((E)=>{
        console.log(E)              
      alert("couldn't get transactions ERR: NO PUDIMOS OBTENER TRANSACCIONES")
      })
  },
  //terminar este en el backend
  async   getCreations(){
    const config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
};
    axios.get(`https://backend-raptoreum.world/app2/creations`,config)
      .then(response => { 
console.log(response)
           if(response.data.length > 0){
            this.pendingCreations=response.data
           }else{
             this.pendingCreations="none"
           }
        })
        .catch(error => {
          alert("couldn't get creations ERR: NO PUDIMOS OBTENER CREACIONES")
          
        });
  },  async   getBalance(){
    this.balanceCargandoSpin=true
console.log("disparando get balance")
const config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
};
        axios.get('https://backend-raptoreum.world/app2/balance',config)
      .then(response => { 
console.log(response)
          this.$store.state.cargando=true
      this.$store.state.wallet.raptoreum=null
      this.$store.state.rtmBalance=0
      if(response.data.notRegisteredAssets != "empty"){
        this.$store.state.notRegisteredAssets=response.data.notRegisteredAssets
      }
   
  
        this.notLogged=false

        if(response.data.raptoreumData.address!='none'){
          
          this.$store.state.wallet.raptoreum=response.data.raptoreumData.address
          this.$store.state.rtmBalance=response.data.raptoreumData.balance
        }else if(response.data.raptoreumData.address=='none'){
          this.$store.state.wallet.raptoreum="none"
        }
  
       this.$store.state.totalAssets=response.data.todosLosAssets
       const raptoreumWorldCoin = this.$store.state.totalAssets.find(assetObj => assetObj.asset === "RAPTOREUMWORLDCOIN");

if (raptoreumWorldCoin) {
  this.$store.state.isRWC=true
} else {
  this.$store.state.isRWC=false
}
         this.$store.state.cargando=false
         this.balanceCargando=false
         this.balanceCargandoSpin=false
        })
        .catch(error => {
          this.$store.state.cargando=false
         this.balanceCargando=false
         this.balanceCargandoSpin=false
          if(error.response)
          {
            if(error.response.data)
            {
              if(error.response.data=="expired"){
                this.$store.state.sessionExpired=true
              }else if(error.response.data=="errorBalance")
              {
                alert("we couldn't get your data ERR: NO LOGRAMOS OBETENER TUS DATOS!")
              }else if(error.response.data=="serverDown"){
                this.$store.state.serverDown=true
              }
             }
          }
        });
      },
  //token de autorizacion listo

  getDate(timestamp){
    const seconds = Math.floor((new Date() - new Date(timestamp)) / 1000);

// Calcula la diferencia en años
let interval = Math.floor(seconds / 31536000);

// Devuelve el tiempo transcurrido en años si es mayor que 1
if (interval > 1) {
  return interval + " years ago";
}

// Calcula la diferencia en meses
interval = Math.floor(seconds / 2592000);
if (interval > 1) {
  return interval + " months ago";
}

// Calcula la diferencia en días
interval = Math.floor(seconds / 86400);
if (interval > 1) {
  return interval + " days ago";
}

// Calcula la diferencia en horas
interval = Math.floor(seconds / 3600);
if (interval > 1) {
  return interval + " hours ago";
}

// Calcula la diferencia en minutos
interval = Math.floor(seconds / 60);
if (interval > 1) {
  return interval + " minutes ago";
}

// Devuelve la diferencia en segundos
return Math.floor(seconds) + " seconds ago";
  }

  ,
  async   getTOTP(){
    const config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
};
    axios.get(`https://backend-raptoreum.world/totp`,config)
      .then(response => { 
        if(response.data != "error" && response.data!= false){
          this.$store.state.modalTOTP.open=true
        this.$store.state.modalTOTP.link=response.data
        }


        })
        .catch(error => {
          console.log(error)
        if(error.respose.data && error.response.data === "error"){
          alert("error trying to get 2fa")
        } 
        if(error.respose.data && error.response.data === "totpRegistered"){
          alert("2fa already active // 2FA YA ACTIVO EN TU CUENTA")
        } 
        });
  },
 
      async openWithdrawModal(){
             this.$store.state.withdrawModalClicked=true
          },
          redirectToPage(tx) {
         
      window.location.href = `https://explorer.raptoreum.com/tx/${tx}`    ;
    },
      async  openCreateAssetModal(){
            this.$store.state.createAssetModalClicked=true
          },
          async  openCreateNftModal(){
            this.$store.state.registerNftModal=true
          },
          searchToken(token){
            this.$store.tokenquery=token
            this.goTo("assetsmarket")
          },
          vender(details){
            console.log("valor para assetAvender:",details)
             this.$store.state.assetAvender=details
             this.$store.state.modalVenderToken=true
             console.log("ejecutado vender")
             console.log(this.$store.state.modalVenderToken)
          },
          detenerVenta(ordenId,asset){
            console.log(ordenId)
            this.socket.emit("detenerVenta",{ token: localStorage.getItem("token"),ventaId:ordenId,asset:asset})
          },openRegistrar(asset){
            try {
             this.$store.state.registerAssetModal=true
             this.$store.state.registerAsset={asset:asset}
            } catch (error) {
              console.log("ERROR  OPEN REGISTRAR")
            }

          }

     }
     }
     </script>
 <style scoped>
 * {
    /* Estilos para navegadores basados en Webkit (Chrome, Safari) */
    ::-webkit-scrollbar {
        width: 8px; /* Ancho del scrollbar */
    }

    ::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.1); /* Fondo del track del scrollbar con transparencia */
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.6); /* Color del thumb del scrollbar con transparencia */
        border-radius: 10px; /* Redondeo del thumb */
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: rgba(0, 0, 0, 0.8); /* Color del thumb cuando se pasa el mouse por encima */
    }

    /* Estilos para Firefox */
    scrollbar-width: thin; /* Ancho del scrollbar: puede ser auto, thin o none */
    scrollbar-color: rgba(0, 0, 0, 0.6) rgba(0, 0, 0, 0.1); /* Color del thumb y del track */
}
 
*{
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
#carouselExampleDark{
  max-width: 100%;
}
.main{height: 100%;
  width: 100%;
  display: flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
background: transparent;
}
#accordionExample{
  display: none;
}
input{
  background-color: #fff;
  border-bottom: 2px solid black;
  color: #000;
}
 .locationDiv{
  width: 80%;  height: 9vh;
  margin-left: 20%;
  background: linear-gradient(to top, #666, white); 
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
 }
 .img-carousel {
    width: auto;
    height: 400px; /* Ajusta la altura según tus necesidades */
    max-height: 100%;
    object-fit: cover; /* Ajusta la imagen para que cubra el contenedor sin distorsión */
    margin: 0 auto; /* Centra la imagen horizontalmente */
}
 .wrapper{
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  grid-auto-rows:minmax(91vh,min-content);
  
  padding: 0px 0;
  min-width: 100%;
  max-width: 100%;
 height: 91vh;
 overflow-x: hidden;
}
.assetpic{
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.nftpic{
  height: 50px;
  width: 50px;

}
.img-accordion{
  width: 48%;
  height: auto
}
.container{
  display: flex;
  flex-direction: row ;
  justify-content: center;
  align-items: center;
background-color: #fff;
cursor: pointer;
overflow: hidden;
width: 90%;
color: #000;
}
.container-balance{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
width: 50%;
}
.container-raptoreum-data{
  display: flex;
  flex-direction: row ;
  justify-content: center;
  align-items: center;
background-color: #fff;
cursor: pointer;
overflow: scroll;
width: 90%;
color: #000;
height: 30vh;
}
.container-transactions-data{
  display: flex;
  flex-direction: column ;
  justify-content: flex-start;
  align-items: center;
background-color: #fff;
overflow: scroll;
width: 90%;
color: #000;
height: 30vh;
}
.containerHome{
  display: flex;
  flex-direction: row ;
  justify-content: center;
  align-items: center;
  background-color: white; 
  padding: 0% 3%;
cursor: pointer;
overflow: hidden;
width: 100%;
color: #000;
height: 91vh;
}

.tokens-wrapper {
  display: grid;
  grid-template-rows: min-content 1fr; /* Ajustar automáticamente la altura de las filas */
  border: 2px solid #000;
  background-color: #fff;
  cursor: pointer;
  max-height: 30vh; /* Altura máxima */
  width: 90%;
  overflow: auto; /* Permitir desplazamiento vertical */
  padding-top: 10px; /* Ajustar según sea necesario */
  padding-bottom: 10px; /* Ajustar según sea necesario */
}
.container-logged{
  display: flex;
  flex-direction: column ;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
background-color: #fff;
cursor: pointer;
overflow: hidden;
width:100%;

}
.container-address{
  display: flex;
  flex-direction: column ;
  justify-content: center;
  align-items: center;
background-color: #fff;
cursor: pointer;
overflow: hidden;
width:50%;

}
.container-wrapper{
display: flex;
flex-direction: column ;
justify-content: center;
align-items: center;
border: 1px solid #000;
background: linear-gradient(to top, #666, white); 
background-color: #fff;
cursor: pointer;
overflow-x:hidden;
width:100%;
height: 91vh;
}

.container-wrapper-busy{
display: flex;
flex-direction: column ;
justify-content: center;
align-items: center;
border: 1px solid #000;
background: linear-gradient(to top, #0ff, white); 
background-color: #fff;
cursor: pointer;
overflow-x:hidden;
width:100%;
height: 91vh;
}
.container-tokens{
  display: flex;
  flex-direction: row ;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #000;
background-color: #fff;
cursor: pointer;
width:100%;
height: 10vh;
}
.container-transactions
{
  display: flex;
  flex-direction: column ;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #000;
background-color: #fff;
cursor: pointer;
width:100%;
height: 13vh;
}
.creation
{
  display: flex;
  flex-direction: row ;
  justify-content: space-around;
  align-items: center;
width:100%;
height: 100%;
}
.transaction
{
  display: flex;
  flex-direction: row ;
  justify-content: center;
  align-items: center;
width:100%;
height: 100%;
}
.assetsRegisteredOptions{
  display: flex;
  flex-direction: row ;
  justify-content: center;
  align-items: center;
background-color: #fff;
cursor: pointer;
overflow: hidden;
width:90%;
height: 5vh;
}
.balance-img{
  height: 7vh;
width: 7vh;
border-radius:100%;
}
.balance-nft{
  max-height: 7vh;

}

 #homeWrapperNotLogged{
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 background-color:#fff; 
 color: #000;
 height:minmax(91vh,min-content);
 width: 80%;
 text-align: center;
 justify-content: center;
 margin-left: 20%;
 overflow: scroll;
 background: linear-gradient(45deg, #888, #ffffff, #888); /* Colores de fondo */
background-size: 200% 200%;
animation: modalMensajeDelCreador 6s ease infinite;
 }

 form input{
 width: 20vw;
 }

 .optionImage{
  width: 570px;
  height:290px;
 }
 input{
  border: 2px solid #000;
  background: linear-gradient(to top, #666, white); 
  color: #000;
font-weight: bold;
 }
 @keyframes modalMensajeDelCreador {

0% {
  background-position: 0% 50%;
}
50% {
  background-position: 100% 50%;
}
100% {
  background-position: 0% 50%;
}

}
 .footer{
  width: 100%;height: 6vh;display: flex; justify-content: space-around;align-items: center;color: #fff; background: linear-gradient(to top,#000, #666); ;font-size: small;font-weight: bold;
 }
 .footer h1,h6,a{
 font-size: 4vh;
 }
@media (max-width: 980px) {
  #accordionExample{
  display: block;
}
  #carouselExampleDark{
  display: none;
}
  .footer{
  width: 100%;height: 4vh;display: flex; justify-content: space-around;align-items: center;color: #fff; background: linear-gradient(to top,#000, #666); ;font-size: small;font-weight: bold;
 }
 .footer h1,h6,a{
 font-size: 1.8vh;
 }
  .container-balance{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
  }
  .container-address{

width:100%;

}
  .container-raptoreum-data{
    flex-direction: column;
    height: 45vh;
  }
  .container-raptoreum-data h1,h2{
   font-size: 3.6vh;
  }
  .containerHome{
    flex-direction: column;
    width: 100%;
  }

  .optionImage{
  width: 320px;
  height: 180px;
 }
 .assetsOptions{
  font-size: 1.6vh;
}
.container-tokens h4{
  font-size: 2.3vh;
}
#homeWrapperNotLogged{
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 background-color:#fff; 
 color: #000;
 height: min-content;
 width: 100%;
 text-align: center;
 justify-content: center;
 margin-left: 0;
 overflow:hidden;
 }
.locationDiv
{
  width: 100%;
  height: 9vh;
  margin-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
 }
 .wrapper{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 5px; /*The space between grid containers*/
  padding:   5px 0px;
  max-width: 100vw;
 
}
.containerLogger{
  flex-direction:row; 
}
  }
  @media(max-height:300px){
     .welcome{

         height:120px;

      }
  }
 
 </style>








