<template>
  <!-- 4 modalMensajeDelCreador. 2 para español ingles version firstClick y 2 version no first click-->
    <div  class="modalMensajeDelCreador"  v-if=" $store.state.idioma!=false && $store.state.idioma=='spanish' && $store.state.pendingToken.firstClick" >

      <div class="half">
          <h1 style="font-size: x-large;">Aviso!</h1>
          <p style="font-size: x-large;">Nuestra inteligencia artificial PROHIBE:</p>
          <ul style="font-size: x-large;">
            <li>DESNUDOS Y CONTENIDO EXPLICITO</li>
            <li>CONTENIDO GORE O SANGRIENTO</li>
            <li>DROGAS</li>
            <li>ARMAS</li>
          </ul>
        

      </div>
 
      <div class="half2">
    
      <h1> si aún así decides subir una foto que no cumpla con nuestras politicas y tienes el balance necesario para crear el token, el costo de crear el token será absorvido de tu billetera sin crear tu token como medida de penalización.</h1> 
      
       <div class="buttons">
           <input type="button" v-on:click="salir()" value="ENTENDIDO 🫡">
       </div>  
    </div>

    
    </div>
    <div  class="modalMensajeDelCreador"  v-if=" $store.state.idioma!=false && $store.state.idioma=='english' && $store.state.pendingToken.firstClick">
     <div  class="half">
      <h1  style="font-size: x-large;">Warning!</h1> <p  style="font-size: x-large;">Our artificial intelligence PROHIBITS:</p> 
      <ul style="font-size: x-large;"> <li>NUDITY AND EXPLICIT CONTENT</li>
         <li>GORE OR BLOODY CONTENT</li> 
         <li>DRUGS</li> 
         <li>WEAPONS</li>
         </ul>
     </div> 

     <div class="half2">
      <h1>If you still decide to upload a photo that does not comply with our policies and you have the necessary balance to create the token, the cost of creating the token will be deducted from your wallet without creating your token as a penalty measure.</h1>
       
    
  
      <div class="buttons">
        <input type="button" v-on:click="salir()" value="UNDERSTOOD 🫡">
      </div>
    </div>
     </div>
 
    
    





     <div  class="modalMensajeDelCreador"  v-if=" $store.state.idioma!=false && $store.state.idioma=='spanish' && !$store.state.pendingToken.firstClick" >

<div class="half">
    <h1>{{$store.state.pendingToken.status}}</h1>
</div>

<div class="half2">

<h1> Puedes ver mas detalles sobre la creación de tus token en el <a href="https://raptoreumworld.com/">  menú principal</a>.</h1> 

 <div class="buttons">
     <input type="button" v-on:click="salir()" value="ENTENDIDO 🫡">
 </div>  
</div>


</div>
<div  class="modalMensajeDelCreador"  v-if=" $store.state.idioma!=false && $store.state.idioma=='english' && !$store.state.pendingToken.firstClick">
<div  class="half">
  <h1>{{$store.state.pendingToken.status}}</h1>
</div> 

<div class="half2">
  <h1>You can see more details about the creation of your tokens in the <a href="https://raptoreumworld.com/">main menu</a>.</h1>
 


<div class="buttons">
  <input type="button" v-on:click="salir()" value="UNDERSTOOD 🫡">
</div>
</div>
</div>
  </template>

  <script>
  import axios from 'axios'
import esArrayIterator from 'core-js/modules/es.array.iterator';
  import { io } from 'socket.io-client'
  
  export default {
    name: 'modal',
    props: {},
    data() {
      return {
        file: null,  // Agregar una variable para almacenar el archivo seleccionado,
        image:"",
        assetDescription:""
      }
    },
    mounted() {
      // Tu código de montaje...
    },
    methods: {
      salir(){ 
        //this.socket.disconnect()
        this.$store.state.pendingToken.clicked=false
      },
    }
  }
  </script>
    
      <style scoped>
 

  input{
    font-size: 3vh;
    height: 4vh;
  }
  
  h1,h2,h3,p{
    font-size: 3vh;
  }
  .description{
    overflow-y: scroll;
    max-height:35%;
    width:60%;
    word-wrap: break-word;
   font-weight: bold;
  }
      #app{
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
      }
      
  .buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
   
  }
  .buttons input{
    width: 50%;
    max-width: 50%;
    height: min-content;
    font-size: 3vh;
  }
     
  .holder{
    display: flex;flex-direction: column;justify-content: center;width: 100%;align-items: center;
  }
  .modalMensajeDelCreador{
    display:flex;
    justify-content:center;
    flex-direction:row;
    align-items:center;
    position:fixed;
    z-index:200;
    left:0;
    top:0;
    width:100vw;
    height:100vh;
    color:#fff;
    text-shadow: 4px 4px 6px black;
    font-family: 'Poppins', sans-serif;
    text-align:center;
    font-size:3vh;
    overflow-y: scroll;
    background: linear-gradient(45deg, #000, #dddd, #000); /* Colores de fondo */
background-size: 200% 200%;
animation: modalMensajeDelCreador 6s ease infinite;
}

@keyframes modalMensajeDelCreador {

   0% {
     background-position: 0% 50%;
   }
   50% {
     background-position: 100% 50%;
   }
   100% {
     background-position: 0% 50%;
   }
  
}
   .card{
    width: 18rem;max-height: 69vh;overflow-y: scroll; 
   }
  .half,.half2{
    width: 50%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
      @keyframes spin {
        0% { transform: rotateY(0deg); }
        100% { transform: rotateY(360deg); }
      }
 @media (max-width:980px) {
    .card{
    width: 18rem;max-width: 18rem;max-height: 30vh;overflow-y: scroll; 
   }
  .description{
    overflow-y: scroll;
    max-height:100%;
    width:25%;
    word-wrap: break-word;
   font-weight: bold;
  }
  .holderElement{
    width: 20%;
  }
  input{
    font-size: 2vh;
    height: 3vh;
  }
  .image-preview{
    border-radius: 50%;
    width:50px;
    height:50px;

  }
  .holder{
    display: flex;flex-direction: row;justify-content: center;width: 100%;align-items: center;height: 70%;border:2px solid #666;
  }
  h1,h2,h3,p{
    font-size: 2vh;
  }
  .half{
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 60%;
  }
    .half2{
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 40%;
    }

    .modalMensajeDelCreador{
          flex-direction: column;
        }
    .buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
    height: 20%;
  }
  .buttons input{
    width: 50%;
  }
  .cost{
    height: 10%;
  }
      }
      @media (max-width:980px) {
        .image-preview{
    border-radius: 50%;
    width:30px;
    height:30px;

  }
      }
  
      </style>
      