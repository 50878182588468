<template>
    <div class="modalMensajeDelCreador" v-if="$store.state.idioma!=false && $store.state.idioma=='spanish'">
    <h1>RaptoreumWorld está cerrado </h1>
    <h1 v-on:click="salir()">(por ahora)</h1>
    <p>volveremos a estar en linea cuando los assets esten 100% activados. estimamos que esto será a mediados de septiembre.
    </p>
  
     <!-- 
        <div class="buttons">
          <input type="button" v-on:click="salir()" value="VOLVER">
        </div>  
     

-->
  </div>
  
      <div class="modalMensajeDelCreador" v-if="$store.state.idioma!=false && $store.state.idioma=='english'">
        <h1>RaptoreumWorld is closed </h1>
        <h1 v-on:click="salir()" >(for now)</h1>
<p>We will be back online when assets are 100% activated. We estimate this will be in mid-September.</p>

     <!-- 
        <div class="buttons">
          <input type="button" v-on:click="salir()" value="GO BACK">
        </div>  
-->
</div>
      <div class="modalMensajeDelCreador" v-if="cargando">
        <div class="spinner-border text-light" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
      </div>
  
    </template>
    
    <script>
   import axios from 'axios';
  import store from '../store/index';
  import { io } from 'socket.io-client';
  import { loadRouteLocation } from 'vue-router';
  
  export default {
    name: 'mesaggeModal',
    props: {
    },
    data() {
      return {
        email:""
      };
    },
    mounted(){
  console.log("modal mounted")
    },
    destroyed(){
      
    },
    methods: {
      sendEmail(){
        if(!this.email.includes("@")){
            alert("invalid email // CORREO INVALIDO")
        }
     axios.post('https://backend-raptoreum.world/app2/recoverAccount',{email:this.email})
      .then((response) => { 
        alert("email sent. SUCCESS: CORREO ENVIADO")
      })
      .catch((E)=>{
                    
      alert("couldn't send email ERR: NO PUDIMOS ENVIAL EL EMAIL")
      })
      },
      salir(){
        this.$store.state.messageModal = false;
      }
    }
  }
    </script>
        
        <!-- Add "scoped" attribute to limit CSS to this component only -->
        <style scoped>
        /* 
        ESTILOS:
        font-family: 'Lexend Peta', sans-serif;
        font-family: 'Poppins', sans-serif;
        font-family: 'Russo One', sans-serif;
        */
    
    
    
    
    
        #app{
          font-family: Avenir, Helvetica, Arial, sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          text-align: center;
          color: #2c3e50;
        }
        
    .buttons{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80%;
      max-width: 80%;
    }
    .buttons input{
      width: 50%;
      max-width: 50%;
    }
       
      
        .modalMensajeDelCreador{
          display:flex;
          justify-content:center;
          flex-direction:column;
          align-items:center;
          position:fixed;
          z-index:100;
          left:0;
          top:0;
          width:100vw;
          height:100vh;
          background-color:rgb(0,0,0,0.9);
          display:flex;
          color:#fff;
          font-family: 'Poppins', sans-serif;
          text-align:center;
          font-size:3vh;
    
        }
     
    
        @keyframes spin {
          0% { transform: rotateY(0deg); }
          100% { transform: rotateY(360deg); }
        }
        @media (max-width:980px) {
          p,h1,h3{
            font-size: 2.6vh;
          }
          input{
            width: 80%;
          }
          .buttons{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 100%;
    }
    .buttons input{
      width: 50%;
      max-width: 50%;
    }
       
        }
    
    
        </style>