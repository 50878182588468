<template>
    <div>
      <div class="modalMensajeDelCreador" v-if="$store.state.idioma === 'spanish'">
        <h3>Configura la Autenticación de Dos Factores</h3>
        <p>Sigue los siguientes pasos para activar la autenticación de dos factores:</p>
        <ol>
          <li>Abre tu aplicación de autenticación (como Google Authenticator o Authy) en tu dispositivo móvil.</li>
          <li>Usa la opción para escanear un nuevo código QR.</li>
          <li>Escanea el siguiente código QR:</li>
        </ol>
        <div class="qr-container">
          <img :src="$store.state.modalTOTP.link" alt="Código QR para 2FA" class="qr">
          <div class="qr-download">
            <i>Descarga el QR para recuperar tus credenciales en caso que se extravíe tu teléfono</i>
            <a :href="$store.state.modalTOTP.link" download="raptoreumworld-2fa.png">
              <input type="button" value="Descargar QR">
            </a>
          </div>
        </div>
        <p>Después de escanear, introduce el código de seis dígitos generado por la aplicación:</p>
        <input type="number" v-model="totpCode" placeholder="Código TOTP" class="input">
        <div class="buttons">
          <input type="button" @click="verifyTOTP" value="Verificar">
          <input type="button" @click="salir" value="Salir">
        </div>
      </div>
  
      <div class="modalMensajeDelCreador" v-if="$store.state.idioma === 'english'">
        <h3>Set Up Two-Factor Authentication</h3>
        <p>Follow these steps to enable two-factor authentication:</p>
        <ol>
          <li>Open your authentication app (such as Google Authenticator or Authy) on your mobile device.</li>
          <li>Use the option to scan a new QR code.</li>
          <li>Scan the following QR code:</li>
        </ol>
        <div class="qr-container">
          <img :src="$store.state.modalTOTP.link" alt="QR Code for 2FA" class="qr">
          <div class="qr-download">
            <i>Download the QR code to recover your credentials in case your phone is lost</i>
            <a :href="$store.state.modalTOTP.link" download="raptoreumworld-2fa.png">
              <input type="button" value="Download QR">
            </a>
          </div>
        </div>
        <p>After scanning, enter the six-digit code generated by the app:</p>
        <input type="number" v-model="totpCode" placeholder="TOTP Code" class="input">
        <div class="buttons">
          <input type="button" @click="verifyTOTP" value="Verify">
          <input type="button" @click="salir" value="Exit">
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import axios from 'axios'
  import store from '../store/index'
  
  export default {
    name: 'modal',
    props: {
      mensaje: String
    },
    data() {
      return {
        totpCode: 0
      };
    },
    methods: {
      salir() {
        this.$store.state.modalTOTP.open = false
      },
      async verifyTOTP() {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
        };
        axios.post(`https://backend-raptoreum.world/verifyTotp`, { code: this.totpCode }, config)
          .then(response => {
            if (localStorage.getItem("idioma") == "english") {
               alert("Successful 2FA set up !");
            }
            if (localStorage.getItem("idioma") == "spanish") {
               alert("¡2FA configurado correctamente!");
            }
            this.$store.state.totp = true;
            return
          })
          .catch(error => {
            if (localStorage.getItem("idioma") == "english") {
              return alert("Try with another code. if the error persists, contact us.");
            }
            if (localStorage.getItem("idioma") == "spanish") {
              return alert("Intenta con otro código. Si el error persiste, contáctanos.");
            }
          });
      }
    }
  }
  </script>
  
      
      <!-- Add "scoped" attribute to limit CSS to this component only -->
      <style scoped>
      /* 
      ESTILOS:
      font-family: 'Lexend Peta', sans-serif;
      font-family: 'Poppins', sans-serif;
      font-family: 'Russo One', sans-serif;
      */
  
  
  
  .qr{
    height: 280px;
    width: 280px;
  }
  
      #app{
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
      }
      
  .buttons{
    display: flex;
    width: 80%;
    max-width: 80%;
  }
  .buttons input{
    width: 50%;
    max-width: 50%;
  }
  .qr-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.qr-download {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  width:50%;
}

.qr {
  max-width:50%;
}
      .modalMensajeDelCreador{
        display:flex;
        justify-content:center;
        flex-direction:column;
        align-items:center;
        position:fixed;
        z-index:100;
        left:0;
        top:0;
        width:100vw;
        height:100vh;
        background-color:rgb(0,0,0,0.9);
        display:flex;
        color:#fff;
        font-family: 'Poppins', sans-serif;
        text-align:center;
        font-size:3vh;
        overflow: scroll;
      }
   
  
      @keyframes spin {
        0% { transform: rotateY(0deg); }
        100% { transform: rotateY(360deg); }
      }
      @media (max-width:980px) {
         
  .qr{
    height: 400px;
    width: 400px;
  }
        p,h1,h3{
          font-size: 2.6vh;
        }
        input{
          width: 80%;
        }
        .buttons{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
  }
  .buttons input{
    width: 50%;
    max-width: 50%;
  }
     
      }
      @media (max-width:500px) {
         
         .qr{
           height: 250px;
           width: 250px;
         }
               p,h1,h3{
                 font-size: 2.6vh;
               }
               input{
                 width: 80%;
               }
               .buttons{
           display: flex;
           flex-direction: column;
           justify-content: center;
           align-items: center;
           width: 100%;
           max-width: 100%;
         }
         .buttons input{
           width: 50%;
           max-width: 50%;
         }
            
             }
  
      </style>
      