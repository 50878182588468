<template>
<nav id="mobileNav" class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
      <div>
        <img src="../assets/eagleworldblack.png" alt="" v-if="$store.state.isRWC==false">
  <img src="../assets/coin-circulo.png" alt="" v-if="$store.state.isRWC==true">
      </div>
      <h1 v-if="$store.state.logged ==true && !$store.state.idioma == false && $store.state.idioma == 'spanish'">¡Bienvenid@ {{ $store.state.usuario }}!</h1>
      <h1 v-if="$store.state.logged ==true && !$store.state.idioma == false && $store.state.idioma == 'english'">¡Welcome {{ $store.state.usuario }}!</h1>
      <h1 v-if="$store.state.logged ==false && !$store.state.idioma == false && $store.state.idioma == 'spanish'">¡Bienvenido a raptoreum world!</h1>
      <h1 v-if="$store.state.logged ==false && !$store.state.idioma == false && $store.state.idioma == 'english'">¡Welcome to raptoreum world!</h1>
      <h1  v-if="$store.state.logged ==true && !$store.state.idioma == false && $store.state.idioma == 'spanish' && $store.state.isRWC==true">¡Bienvenido {{ $store.state.usuario }}, RWC holder!</h1>
      <h1 v-if="$store.state.logged ==true && !$store.state.idioma == false && $store.state.idioma == 'english' && $store.state.isRWC==true">¡Welcome {{ $store.state.usuario }}, RWC holder!</h1>
  
</div>
  <div  id="usuario">
    
   


      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span  class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav" v-if=" !$store.state.idioma == false && $store.state.idioma == 'spanish'">
      
          <li v-on:click="closeMobileNav()"  class="nav-item">
            <router-link to="/" class="enlace2">Inicio</router-link>
          </li>
          <li v-on:click="closeMobileNav()"  class="nav-item">
            <router-link to="/assetsmarket" class="enlace2">Coin market</router-link>
          </li>
     
          <li v-on:click="closeMobileNav()"  class="nav-item">
            <router-link to="/nftmarket" class="enlace2">Mercado NFT</router-link>
          </li>
          <li v-if="$store.state.logged == false" class="nav-item">
            <router-link  class="enlace2"  v-on:click="closeMobileNav()" to="/ingresar">Iniciar sesión</router-link> 
          </li>
          <li v-if="$store.state.logged == false" class="nav-item">
            <router-link   class="enlace2" v-on:click="closeMobileNav()" to="/registrarse">Registrarse</router-link> 
          </li>
          <li v-on:click="closeMobileNav()"  class="nav-item">
            <router-link to="/transactions" class="enlace2">recompensas</router-link>
          </li>
  
          <li v-on:click="$store.dispatch('cerrarSesion')"  v-if="$store.state.logged == true" class="nav-item">
           <h1 style="font-size: x-large;color: #000;">salir</h1>
          </li>
          <div class="enlace3">
  <h1 style="font-size: small;">Selecciona tu idioma:</h1>
  <div style="display: flex;justify-content: center;align-items: center;">
    <img src="../assets/españa.png" v-on:click='$store.dispatch("setTokenSpanish")' style="height: 4vh;width:4vh;cursor: pointer;" alt="">
    <h3 style="cursor: pointer;" v-on:click='$store.dispatch("setTokenSpanish")'>Spanish</h3>
  </div>
  <div style="display: flex;justify-content: center;align-items: center;">
    <img src="../assets/english.png" v-on:click='$store.dispatch("setTokenEnglish")' style="height: 4vh;width:4vh;cursor: pointer;" alt="">
    <h3 style="cursor: pointer;" v-on:click='$store.dispatch("setTokenEnglish")'>English</h3>
  </div>
  </div>
        </ul>


        <ul class="navbar-nav" v-if=" !$store.state.idioma == false && $store.state.idioma == 'english'">
      
      <li v-on:click="closeMobileNav()"  class="nav-item">
        <router-link to="/" class="enlace2">Home</router-link>
      </li>
      <li v-on:click="closeMobileNav()"  class="nav-item">
        <router-link to="/assetsmarket" class="enlace2">Coin market</router-link>
      </li>
      <li v-on:click="closeMobileNav()"  class="nav-item">
        <router-link to="/nftmarket" class="enlace2">NFT market</router-link>
      </li>
      <li v-if="$store.state.logged == false" class="nav-item">
        <router-link  class="enlace2"  v-on:click="closeMobileNav()" to="/ingresar">Sign in</router-link> 
      </li>
      <li v-if="$store.state.logged == false" class="nav-item">
        <router-link   class="enlace2" v-on:click="closeMobileNav()" to="/registrarse">Sign up</router-link> 
      </li>
      <li v-on:click="closeMobileNav()"  class="nav-item">
            <router-link to="/transactions" class="enlace2">rewards</router-link>
          </li>
  
      <li v-on:click="$store.dispatch('cerrarSesion')"   v-if="$store.state.logged == true" class="nav-item">
       <h1 style="font-size: x-large;color: #000;">Log out</h1>
      </li>
      <div class="enlace3">
  <h1 style="font-size: small;">Select your language:</h1>
  <div style="display: flex;justify-content: center;align-items: center;">
    <img src="../assets/españa.png" v-on:click='$store.dispatch("setTokenSpanish")' style="height: 4vh;width:4vh;cursor: pointer;" alt="">
    <h3 style="cursor: pointer;" v-on:click='$store.dispatch("setTokenSpanish")'>spanish</h3>
  </div>
  <div style="display: flex;justify-content: center;align-items: center;">
    <img src="../assets/english.png" v-on:click='$store.dispatch("setTokenEnglish")' style="height: 4vh;width:4vh;cursor: pointer;" alt="">
    <h3 style="cursor: pointer;" v-on:click='$store.dispatch("setTokenEnglish")'>english</h3>
  </div>
  </div>
    </ul>
      </div>

    </div>
  </nav>
</template>
<script>

export default {
      name: 'mobileNav',
      components: {
      
  },
  data(){
        return{
          message: "el socket no ha funcionado",
          times:[],
          encuestasRespondidas:0,
          usuario:localStorage.getItem("usuario") || false,
          token:localStorage.getItem("token") || false,
        }
      },
  mounted() {
  


  },
  beforeDestroy() {
    
  },methods:{
  closeMobileNav:()=>{
    document.querySelector("#navbarNav").classList.toggle("show")
    },

      
    }


     }
</script>
<style scoped>
/* 
ESTILOS:
font-family: 'Lexend Peta', sans-serif;
font-family: 'Poppins', sans-serif;
font-family: 'Russo One', sans-serif;
*/

#app{
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#usuario h1,h3{
  text-align: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size:20px ;
}
nav {
  padding: 30px;

}

.desktopNav div img{
      height:30vh;
      width:30vh;

    }
li h1{
  font-size: large;
}
img{
  animation: spin 8s infinite linear;
  height: 28vh;
}
#serviciosDropdown{
  font-size:3vh;
  margin-top: 3vh;
}

#desktopNav{

      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      min-width:20vw;
      max-width:20vw;
      background-color: #000;
      
    }
    .dropdown{
      display: flex;
      justify-content: center;
      align-items: center;
      width:20vw;
    }

.nav div{

  background-color:#fff;
}
#mobileNav{
  display: none;
  width: 100%;

  
}
#desktopNav ul{
  width: 100%;
  text-align: center;
}

#desktopNav div{
max-width: 100%;

}
#desktopNav h2{
max-width: 20vw;
word-wrap: break-word;
}
.whiteDiv h2
{
  color:#000
}

.nav-link{
  font-size: xx-large;
  color: #fff;
}
#desktopNav .dropdown-menu{
max-height:40vh;
overflow:scroll;
}


#desktopNav img{
max-width: 20vw;
}
.navWrapper{
  display: flex;
  flex-direction: row;
padding: 0;

justify-content: center;
align-items: center;
  background-color: #000 !important;
  width:20vw;
  height: 9vh;

}
.green-text {
  color:#004036;
}
.navWrapper li{
width: 100%;
height: 9vh;
display: flex;
justify-content: center;
align-items: center;
}


a.router-link-exact-active {
  color: #000;
  font-size:xx-large;
}
.nav-link{
color:#ffff;
}
.modalMensajeDelCreador{
  display:flex;
  justify-content:center;
  flex-direction:column;
  align-items:center;
  position:fixed;
z-index:100;
  left:0;
  top:0;
  width:100vw;
  height:100vh;
  background-color:rgb(0,0,0,0.7);
  display:flex;
  color:#fff;
  font-family: 'Poppins', sans-serif;
  transition:0.5s;
  text-align:center;
  font-size:3vh;
}
.advertenciaDeMensaje{
  font-weight:bold;
  font-size:5vh
}
.mensaje{
background-color:#004036;
height:60vh;
width:80vw;
overflow:scroll;
}

.enlace3{
  display: flex;
  justify-content: space-around;
  flex-direction:column;
align-items: center;
margin-top:7%;
cursor: pointer;
color: #000;
font-size: 3vh;
text-decoration:none;
}
.enlace2{
  color: #666;
  font-size: x-large;
}
@keyframes spin {
  0% { transform: rotateY(0deg); }
  100% { transform: rotateY(360deg); }
}
/*subirlo de 980 haria tener problemas al mobilenav*/
@media (max-width:980px) {
  #mobileNav{
  display: flex;
  background-color: #fff;
}
#desktopNav{
  display: none;
}
.modalMensajeDelCreador{
  display:flex;
  justify-content:center;
  flex-direction:column;
  align-items:center;
  position:fixed;
  z-index:100;
  left:0;
  top:0;
  width:100vw;
  height:100vh;
  background-color:rgb(0,0,0,0.7);
  display:flex;
  color:#fff;
  font-family: 'Poppins', sans-serif;
  transition:0.5s;
  text-align:center;
  padding:3vh;
  overflow:scroll;
}
}

</style>

