<template>

<desktopNav></desktopNav>
<mobileNav></mobileNav>
<div v-if="!$store.state.idioma || $store.state.languageModal == true" class="idioma">
    <h1>Select your language:</h1>
    <img src="./assets/españa.png" v-on:click='$store.dispatch("setTokenSpanish")' style="height: 6vh;width:6vh;cursor: pointer;" alt="">
    <h3 style="cursor: pointer;" v-on:click='$store.dispatch("setTokenSpanish")'>Spanish</h3>
    <img src="./assets/english.png" v-on:click='$store.dispatch("setTokenEnglish")' style="height: 6vh;width:6vh;cursor: pointer;" alt="">
    <h3 style="cursor: pointer;" v-on:click='$store.dispatch("setTokenEnglish")'>English</h3>
</div>

<div v-if="$store.state.cargando == true" class="loading">
 
    <img src="./assets/w-nav.png"  style="height: 25vh; width:25vh;cursor: pointer;" alt="">
    <h1>Loading...</h1>
  
</div>

<div v-if="$store.state.sessionExpired == true && !$store.state.idioma == false && $store.state.idioma == 'english'" class="idioma"> 
<h1>Session expired</h1>
<h1> Please log in if you want to keep using the app.</h1>
<input type="button" value="Go to home" v-on:click="closeExpiredModal">
</div>


<div v-if="$store.state.sessionExpired == true && !$store.state.idioma == false && $store.state.idioma == 'spanish'" class="idioma">
<h1>Sesión caducada</h1>
<h1> Porfavor, inicia sesión si quieres seguir usando la app.</h1>
<input type="button" value="Volver al inicio" v-on:click="closeExpiredModal">
</div>

<div v-if="$store.state.registeredCorrectly == true  && $store.state.idioma == 'spanish'" class="idioma">
<h1> ¡Registrado exitosamente!</h1>
<h1>revisa tu inbox y verifica tu cuenta.</h1>

<input type="button" value="go to login" v-on:click="goToLogin">
</div>

<div v-if="$store.state.registeredCorrectly == true  && $store.state.idioma == 'english'" class="idioma">
<h1>Successfully registered! </h1>
<h1>check your inbox and verify your account. </h1>

<input type="button" value="go to login" v-on:click="goToLogin">
</div>


  <router-view/>
</template>
<script>
import axios from 'axios'
import messageModal from '@/components/messageModal.vue'  
import desktopNav from '@/components/desktopNav.vue'
import mobileNav from '@/components/mobileNav.vue'




export default {
      name: 'HomeView',
      components: {
       desktopNav,mobileNav,messageModal
  },data(){
       return {
   
    }
      },
  created()
   {
    this.$store.state.messageModal = true;
    try {
      this.$store.state.cargando=true
    const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  console.log("token del parametro:",token)
  // Guardar el token en el localStorage
  if (token && token.length > 10) {
    console.log("aki 1")
    localStorage.removeItem("token")
    localStorage.setItem("token",token)
    this.verifySession()
  }else if(!token || token.length < 10){
    console.log("aki 2")
    this.verifySession()
  }
  
    } catch (error) {
      this.$store.state.cargando=false
    }

  },
  beforeDestroy() {
    
  },methods:{
  closeMobileNav:()=>{
    document.querySelector("#navbarNav").classList.toggle("show")
    },
    async verifySession(){
      //hacer modal y sacarlo cuando todo termine de cargar 
      console.log("EL TOKEN QUE VAMOS A ENVIAR!", localStorage.getItem("token"))
      axios.post("https://backend-raptoreum.world/app2/verify", {
        token: localStorage.getItem("token")})
      .then(async (res)=>{
          console.log(res)
       await this.$store.dispatch("setData", {token:res.data.token,usuario:res.data.usuario,totp:res.data.totp});
      this.$store.state.cargando=false

      }).catch(err=>
        {            this.$store.state.cargando=false
          console.log(err)
          try {
            if(err.response.data){
            if(err.response.data == "expired" ){
              this.$store.state.sessionExpired=true
            }else if(err.response.data == "notLogged"){
                localStorage.removeItem("token")
                localStorage.removeItem("usuario")
            }
          }
          } catch (error) {
            this.$store.state.cargando=false
          }
          
        
        })
    },
  
    closeExpiredModal(){
      this.$store.state.sessionExpired=false
      localStorage.removeItem("token")
      localStorage.removeItem("usuario")
      window.location.href='https://raptoreumworld.com/'
    },
    goToLogin(){
    
      window.location.href='https://raptoreumworld.com/ingresar'
    }

    }


     }
</script>
<style scoped>
/* 
ESTILOS:
font-family: 'Lexend Peta', sans-serif;
font-family: 'Poppins', sans-serif;
font-family: 'Russo One', sans-serif;
*/

#app{
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

}

.desktopNavDisplayerArrows{
  position: absolute;
  left: 20vw;
top: 50%;

  height: 5vh;
  width: 5vh;
}
.desktopNav div img{
      height:30vh;
      width:30vh;

    }
li h1{
  font-size: large;
}
img{
  animation: spin 8s infinite linear;
  height: 28vh;
}
#serviciosDropdown{
  font-size:3vh;
  margin-top: 3vh;
}
.broughtDown{

        display:flex;
        justify-content:center;
        flex-direction:column;
        align-items:center;
        position:fixed;
        z-index:100;
        left:0;
        top:0;
        width:100vw;
        height:100vh;
        background-color:#fff;
        display:flex;
        color:#000;
        font-family: 'Poppins', sans-serif;
        text-align:center;

}
#desktopNav{

      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      min-width:20vw;
      max-width:20vw;
      background-color: #000;
      
    }
    .dropdown{
      display: flex;
      justify-content: center;
      align-items: center;
      width:20vw;
    }

.nav div{

  background-color:#fff;
}
#mobileNav{
  display: none;
  
}
#desktopNav ul{
  width: 100%;
  text-align: center;
}

#desktopNav div{
max-width: 100%;

}
#desktopNav h2{
max-width: 20vw;
word-wrap: break-word;
}
.whiteDiv h2
{
  color:#000
}

.nav-link{
  font-size: xx-large;
  color: #fff;
}
#desktopNav .dropdown-menu{
max-height:40vh;
overflow:scroll;
}


#desktopNav img{
max-width: 20vw;
}
.navWrapper{
  display: flex;
  flex-direction: row;
padding: 0;

justify-content: center;
align-items: center;
  background-color: #000 !important;
  width:20vw;
  height: 9vh;

}
.navWrapper li{
width: 100%;
height: 9vh;
display: flex;
justify-content: center;
align-items: center;
}

.idioma{
  height: 100vh;
  width: 100vw;
  background: linear-gradient(to top, #666, white); 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0;
  top:0;
  position: absolute;
  z-index: 1000;
}

.loading{
  height: 100%;
  width: 100%;
  background-color:white; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0;
  top:0;
  position: absolute;
  z-index: 1000;
}

@keyframes spin {
  0% { transform: rotateY(0deg); }
  100% { transform: rotateY(360deg); }
}
/*subirlo de 980 haria tener problemas al mobilenav*/
@media (max-width:980px) {
  #mobileNav{
  display: flex;
  background-color: #fff;
}
#desktopNav{
  display: none;
}
.modalMensajeDelCreador{
  display:flex;
  justify-content:center;
  flex-direction:column;
  align-items:center;
  position:fixed;
  z-index:100;
  left:0;
  top:0;
  width:100vw;
  height:100vh;
  background-color:rgb(0,0,0,0.7);
  display:flex;
  color:#fff;
  font-family: 'Poppins', sans-serif;
  transition:0.5s;
  text-align:center;
  padding:3vh;
  overflow:scroll;
}
}

</style>

