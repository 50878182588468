<template>
  <div class="modalMensajeDelCreador" v-if="$store.state.idioma!=false && $store.state.idioma=='spanish'  && $store.state.modalVenderToken && cargando == false">
  <h1>Vender activo</h1>
  <p>Nombre del activo: {{ $store.state.assetAvender.asset }}</p>
  <p>Precio: {{ " " + precio + " " + "Raptoreum (RTM)" }}</p>
  <input type="number" v-model="precio">
  <p>*Todo tu colateral estará expuesto al mercado. Si te arrepientes de vender, puedes detener la venta en cualquier momento*</p>
  <h3>Tienes {{ ($store.state.assetAvender.balance) + " " + $store.state.assetAvender.asset + " " + "(" + $store.state.assetAvender.acronimo + ")" }}</h3>
  <div v-if="$store.state.totp===true" style="display: flex; width: 80%; max-width: 80%;">
    <p>Autenticación 2fa:</p>
    <input type="text" placeholder="Código 2fa" v-model="$store.state.totpCode">
  </div>
  <div class="buttons">
    <input type="button" v-on:click="vender()" value="VENDER">
    <input type="button" v-on:click="salir()" value="VOLVER">
  </div>
</div>

    <div class="modalMensajeDelCreador" v-if="$store.state.idioma!=false && $store.state.idioma=='english'  && $store.state.modalVenderToken && cargando == false">
      <h1>Sell asset</h1>
      <p>asset name:{{ $store.state.assetAvender.asset }}</p>
      <p>price:{{ " "+precio +" "+"Raptoreum (RTM)" }}</p>
      <input type="number"  v-model="precio">
      <p>*All your collateral will be exposed to the market. If you regret selling, you can stop the sale at any time*</p>
      <h3>you've got {{ ($store.state.assetAvender.balance) + " "+$store.state.assetAvender.asset+" "+"("+$store.state.assetAvender.acronimo+")"}}</h3>
      <div v-if="$store.state.totp===true" style="display: flex; width: 80%;  max-width: 80%;" >
      <p>2fa authentication:</p>
        <input type="text" placeholder="2fa code" v-model="$store.state.totpCode" >
      </div>
      <div class="buttons">
        <input type="button" v-on:click="vender()" value="SELL">
        <input type="button" v-on:click="salir()" value="GO BACK">
      </div>  
    </div>

    <div class="modalMensajeDelCreador" v-if="cargando">
      <div class="spinner-border text-light" role="status">
  <span class="visually-hidden">Loading...</span>
</div>
    </div>

  </template>
  
  <script>
 import axios from 'axios';
import store from '../store/index';
import { io } from 'socket.io-client';
import { loadRouteLocation } from 'vue-router';

export default {
  name: 'venderAssetModal',
  props: {
  },
  data() {
    return {
      cargando:false,
      validAddres:null,
      balance:null,
      precio:0,
      validWithdraw:null,
      selectedOption:'',
      password:''
    };
  },
  mounted(){
    
    try {


         
console.log("connecting");

this.socket = io('https://backend-raptoreum.world/', {
    withCredentials: false,
    query: {
        "key": "skrillex",
        "object": JSON.stringify({ token: localStorage.getItem("token"),subject:"venderToken" })
    }
});

this.socket.on('connect', () => {
  this.$store.state.cargando = false;
});

} catch (error) {

}
try {
  this.socket.on("successAssetToMarket",(data)=>{
    this.cargando=false
  let foundIndex = this.$store.state.totalAssets.findIndex(e => e.asset === data.asset);
  
  if (foundIndex !== -1) {
     console.log("asset encontrado")
     this.$store.state.totalAssets[foundIndex]._id=data._id  
     this.$store.state.totalAssets[foundIndex].enVenta=true
  } 
       
  alert('Token sale is now live :D SUCCESS: EMPIEZA LA VENTA DEL TOKEN! :D')
})
this.socket.on("errorStoppingSell", () => {
  this.cargando=false
    alert("Error trying to stop sell :/ ERR: ERROR INTENTANDO PARAR LA VENTA");
  });

  this.socket.on("expired", () => {
    this.cargando=false
    this.$store.state.sessionExpired = true;
  });

  this.socket.on('ventaUsuarioDetenida', (data) => {
    let foundIndex = this.$store.state.totalAssets.findIndex(e => e._id === data);
    if (foundIndex !== -1) {
      const { enVenta, _id, ...rest } = this.$store.state.totalAssets[foundIndex];
      const updatedAsset = { ...rest };
      this.$store.state.totalAssets[foundIndex] = updatedAsset;
    }
    this.cargando=false
    alert("Sale successfully stopped SUCCESS: VENTA DETENIDA CORRECTAMENTE");
  });

  this.socket.on("notSelling", () => {
    this.cargando=false
    alert("Asset not in market. ERR: EL ASSET NO ESTÁ EN EL MARKET");
  });

  this.socket.on("invalidTOTP", () => {
    this.cargando=false
    alert("Invalid TOTP code. ERR: CÓDIGO TOTP INVÁLIDO");
  });

  this.socket.on("errorTOTP", () => {
    this.cargando=false
    alert("Error with TOTP. ERR: ERROR CON TOTP");
  });

  this.socket.on("blockedAccount", () => {
    this.cargando=false
    alert("Account is blocked. ERR: CUENTA BLOQUEADA");
  });

  this.socket.on("assetNotFoundInWallet", () => {
    this.cargando=false
    alert("Asset not found in wallet. ERR: ASSET NO ENCONTRADO EN LA CARTERA");
  });

  this.socket.on("selling", () => {
    this.cargando=false
    alert("Asset is already on sale. ERR: ASSET YA ESTÁ EN VENTA");
  });

  this.socket.on("expired", () => {
    this.cargando=false
    alert("Session expired. ERR: SESIÓN EXPIRADA");
  });

  this.socket.on("notExists", () => {
    this.cargando=false
    alert("Asset does not exist. ERR: ASSET NO EXISTE");
  });

  this.socket.on("assetToMarketError", (e) => {
    this.cargando=false
    alert(`Error placing asset on market: ${e}`);
  });

 // this.socket.on("successAssetToMarket", (result) => {
 //   alert("Asset successfully placed on market. SUCCESS: ASSET COLOCADO EN EL MERCADO");
 //   this.cargando=false
 //   this.$store.state.totalAssets.push(result); // Actualizar el estado con el nuevo asset en venta
 // });

  this.socket.on("serverDown", () => {
    this.cargando=false
    alert("Server is down. ERR: SERVIDOR CAÍDO");
  });


} catch (error) {
  
}


  },
  destroyed(){
    this.socket.disconnect()
  },
  methods: {
    vender(){
      if(typeof(this.precio) =="number" && this.precio >0){
        this.cargando=true
        this.socket.emit("assetToMarket",{asset:this.$store.state.assetAvender.asset,token: localStorage.getItem("token"),price:parseFloat(this.precio),totp:this.$store.state.totpCode})
  
      }else{
        alert("not valid price. ERR: PRECIO INVALIDO.")
      }

    },
    salir(){
      this.$store.state.modalVenderToken = false;
    }
  }
}
  </script>
      
      <!-- Add "scoped" attribute to limit CSS to this component only -->
      <style scoped>
      /* 
      ESTILOS:
      font-family: 'Lexend Peta', sans-serif;
      font-family: 'Poppins', sans-serif;
      font-family: 'Russo One', sans-serif;
      */
  
  
  
  
  
      #app{
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
      }
      
  .buttons{
    display: flex;
    width: 80%;
    max-width: 80%;
  }
  .buttons input{
    width: 50%;
    max-width: 50%;
  }
     
    
      .modalMensajeDelCreador{
        display:flex;
        justify-content:center;
        flex-direction:column;
        align-items:center;
        position:fixed;
        z-index:100;
        left:0;
        top:0;
        width:100vw;
        height:100vh;
        background-color:rgb(0,0,0,0.9);
        display:flex;
        color:#fff;
        font-family: 'Poppins', sans-serif;
        text-align:center;
        font-size:3vh;
  
      }
   
  
      @keyframes spin {
        0% { transform: rotateY(0deg); }
        100% { transform: rotateY(360deg); }
      }
      @media (max-width:980px) {
        p,h1,h3{
          font-size: 2.6vh;
        }
        input{
          width: 80%;
        }
        .buttons{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
  }
  .buttons input{
    width: 50%;
    max-width: 50%;
  }
     
      }
  
  
      </style>
      