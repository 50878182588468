<template>
    <div class="modalMensajeDelCreador" v-if="$store.state.idioma!=false && $store.state.idioma=='spanish'">
      <p>Este asset no se ha registrado en raptoreumworld, por lo tanto, debemos registrarlo para que puedas venderlo y transaccionar con el.</p>
      <h1>Registrar asset:</h1>
      <p>-Nombre del asset : {{ $store.state.registerAsset.asset }}  ({{acronym}})</p>
      <p>Escoge un acronimo correcto para el asset (El acronimo de Bitcoin es BTC, el de ethereum es ETH)  DEBE CONTENER 3 O 4 LETRAS</p>
      <input type="text" v-model="acronym" maxlength="4" @input="acronym = acronym.toUpperCase()">
      <p>PRECIO: 500 RAPTOREUM, PRECIO PARA RWC HOLDERS: 0 RAPTOREUM</p>
      <input type="button" value="verificar descuento" v-on:click="getBalance()">
      <div class="spinner-border" role="status" v-if="balanceCargandoSpin">
               <span class="visually-hidden">Loading...</span>
              </div>   
      <h1 v-if="discount===false">RWC no encontrado en tu billetera. no se aplicarán descuentos.</h1>   
      <h1 v-if="discount===true">RWC encontrado en tu billetera! se aplicarán descuentos!</h1>     
      <div class="buttons">
        <input type="button" v-on:click="register()" value="REGISTER">
        <input type="button" v-on:click="salir()" value="EXIT">
      </div>  
    </div>
    <div class="modalMensajeDelCreador" v-if="$store.state.idioma!=false && $store.state.idioma=='english'" >
        <p>This asset has not been registered in Raptoreumworld; therefore, we need to register it so you can sell and transact with it.</p>
      <h1>Register asset</h1>
      <p>-asset name: {{ $store.state.registerAsset.asset }} ({{acronym}})</p>
      <p>choose a correct symbol for the asset (Bitcoin's symbol is BTC, ethereum's symbol is ETH) IT MUST CONTAIN 3 OR 4 LETTERS</p>
      <input type="text" v-model="acronym" maxlength="4"  @input="acronym = acronym.toUpperCase()">
      <p>COST: 500 RAPTOREUM, RWC HOLDERS COST: 0 RAPTOREUM</p>

      <div class="spinner-border" role="status" v-if="balanceCargandoSpin">
               <span class="visually-hidden">Loading...</span>
              </div>   
      <h1 v-if="discount===false">RWC not found in wallet. no discount will be applied.</h1>   
      <h1 v-if="discount===true">RWC found in wallet! discount will be applied!</h1>   
      <div v-if="$store.state.totp===true" style="display: flex; width: 80%;  max-width: 80%;" >
      <p>2fa authentication:</p>
        <input type="text" placeholder="2fa code" v-model="$store.state.totpCode" >
      </div>
      <div class="buttons">
        <input type="button" v-on:click="register()" value="REGISTER">
        <input type="button" v-on:click="salir()" value="EXIT">
      </div>  
    </div>

  </template>
  
  <script>
  import axios from 'axios'
  import store from '../store/index'
  import {io} from 'socket.io-client'
import { getTransitionRawChildren } from 'vue';
  export default {
    name: 'modal',
    props: {
      mensaje: String
    },
    data() {
      return {
        newUsername:'',
        validAddres:null,
        balance:null,
        validWithdraw:null,
        selectedOption:'',
        password:'',
        acronym:'',
        discount:null,
        balanceCargandoSpin:false
     } },
    mounted(){
  
    },
    destroyed(){
   
    },
    methods: {  
  async getBalance(){
    this.balanceCargandoSpin=true
console.log("disparando get balance")
const config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
};
        axios.get('https://backend-raptoreum.world/app2/balance',config)
      .then(response => { 
console.log(response)
          this.$store.state.cargando=true
      this.$store.state.wallet.raptoreum=null
      this.$store.state.rtmBalance=0
      if(response.data.notRegisteredAssets != "empty"){
        this.$store.state.notRegisteredAssets=response.data.notRegisteredAssets
      }
   
  
        this.notLogged=false

        if(response.data.raptoreumData.address!='none'){
          
          this.$store.state.wallet.raptoreum=response.data.raptoreumData.address
          this.$store.state.rtmBalance=response.data.raptoreumData.balance
        }else if(response.data.raptoreumData.address=='none'){
          this.$store.state.wallet.raptoreum="none"
        }
  
       this.$store.state.totalAssets=response.data.todosLosAssets
       const raptoreumWorldCoin = this.$store.state.totalAssets.find(assetObj => assetObj.asset === "RAPTOREUMWORLDCOIN");
if (raptoreumWorldCoin) {
  this.$store.state.isRWC=true
  this.discount=true
} else {
  this.$store.state.isRWC=false
  this.discount=false
}
         this.$store.state.cargando=false
         this.balanceCargando=false
         this.balanceCargandoSpin=false
        })
        .catch(error => {
          this.discount=null
          this.$store.state.cargando=false
         this.balanceCargando=false
         this.balanceCargandoSpin=false
          if(error.response)
          {
            if(error.response.data)
            {
              if(error.response.data=="expired"){
                this.$store.state.sessionExpired=true
              }else if(error.response.data=="errorBalance")
              {
                alert("we couldn't get your data ERR: NO LOGRAMOS OBETENER TUS DATOS!")
              }else if(error.response.data=="serverDown"){
                this.$store.state.serverDown=true
              }
             }
          }
        });
      }
      ,
    salir(){
        this.$store.state.registerAssetModal=false
    },
    async register(){
       let register=this.$store.state.registerAsset
       if(this.acronym.length < 3){
        alert("INVALID SYMBOL ERR: ACRONIMO DEL ASSET INVALIDO")
       }
       if (/\d/.test(this.acronym)) {
        alert("The symbol cannot contain letters ERR: El acrónimo solo debe contener letras");
      }
      const config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
};
        axios.post('https://backend-raptoreum.world/app2/registerAsset',{asset:register.asset,acronym:this.acronym,totp:this.$store.state.totpCode},config)
        .then(response => {  
          alert("register asset successful! SUCCESS: ASSET REGISTRADO CON EXITO" )
          this.$store.state.notRegisteredAssets = this.$store.state.notRegisteredAssets.filter(asset => asset.asset !== response.data.asset);
          this.$store.state.totalAssets.push(response.data)
        })
      
        .catch(error => {
          console.log(error)
          if(error.response && error.response.data){
            if(error.response.data=="notEnoughBalance"){
                alert("not enough balance ERR : BALANCE INSUFICIENTE")
            }else if(error.response.data=="expired"){
                this.$store.state.sessionExpired=true
            }else if(error.response.data=="assetExists"){
                 alert('the asset is already registered ERR: ASSET YA REGISTRADO')
            }else if(error.response.data=="registerAssetError"){
              alert("register asset error, try again later ERR: ERROR REGISTRANDO ASSET")
            }else if(error.response.data == "notFoundInWallet"){
            alert("asset not in your wallet ERR : ASSET NO ENCONTRADO EN TU BILLETERA")
            }else if(error.response.data =="errorGettingStock"){
              alert("register asset error, try again later ERR: ERROR REGISTRANDO ASSET")
            } 
            else if(error.response.data=="invalidTotp"){
      alert("INVALID 2FA CODE ERR: CODIGO 2FA INVALIDO")
    
    }  else if(error.response.data=="totpError"){
      alert("2FA ERROR: ERROR CHECKEANDO 2FA")
    
    }
          } else if(error.response.data && error.response.data.errors){
  
    if(error.response.data.errors[0].path == "acronym"){
        alert("acronym should be 3 or 4 characters ERR: EL ACRONIMO DEBE SER DE 3 O 4 LETRAS")
        return
      }
    }
         
        });
      
    }
    }
  }

  </script>
      
      <!-- Add "scoped" attribute to limit CSS to this component only -->
      <style scoped>
      /* 
      ESTILOS:
      font-family: 'Lexend Peta', sans-serif;
      font-family: 'Poppins', sans-serif;
      font-family: 'Russo One', sans-serif;
      */
  
  
  
  
  
      #app{
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
      }
      
  .buttons{
    display: flex;
    width: 80%;
    max-width: 80%;
  }
  .buttons input{
    width: 50%;
    max-width: 50%;
  }
     
    
      .modalMensajeDelCreador{
        display:flex;
        justify-content:center;
        flex-direction:column;
        align-items:center;
        position:fixed;
        z-index:100;
        left:0;
        top:0;
        width:100vw;
        height:100vh;
        background-color:rgb(0,0,0,0.9);
        display:flex;
        color:#fff;
        font-family: 'Poppins', sans-serif;
        text-align:center;
        font-size:3vh;
  
      }
   
  
      @keyframes spin {
        0% { transform: rotateY(0deg); }
        100% { transform: rotateY(360deg); }
      }
      @media (max-width:980px) {
        p,h1,h3{
          font-size: 2.6vh;
        }
        input{
          width: 80%;
        }
        .buttons{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
  }
  .buttons input{
    width: 50%;
    max-width: 50%;
  }
     
      }
  
  
      </style>
      