<template>
    <pendingAssetModal v-if="$store.state.pendingToken.clicked==true"></pendingAssetModal>
   <div  class="modalMensajeDelCreador"  v-if=" $store.state.idioma!=false && $store.state.idioma=='spanish'">
     <div  class="half">
      <h1>Crear NFT:</h1>
      <p style="font-weight: bold;">Nombre del NFT: (debe ser un nombre no usado por otro NFT):</p>
      <input type="text" v-model="$store.state.createTokenName">
      <p style="font-weight: bold;"> descripción del NFT  (dile a la gente de que trata tu NFT):</p>
      <textarea name="" id="" v-model="assetDescription"></textarea>

      <p style="font-weight: bold;">Selecciona la foto del NFT:</p>
      <h3>*la imagen no debe exeder 2 MB.*</h3>
      <input type="file" accept-language="en" @change="handleFileUpload">
     </div> 

     <div class="half2">
      <div class="holder">
        <h1  class="holderElement" style="font-weight: bold;">Resultado final:</h1>
 <div class="card" :key="index">
            <img  v-if="image != ''" :src="image" alt="Uploaded Image">
            <div class="card-body">
    <h1  style="color:#000">{{$store.state.createTokenName }}</h1>
    <p class="card-text" style="color:#000">{{assetDescription}}</p>
  </div>
</div>
       

</div>
       
    
      <p style="margin-top: 1vh;font-weight: bold;font-size: small;">*crear tu NFt con nosotros te garantiza acceso directo a nuestro mercado. NFT externos no pueden ser registrados en RaptoreumWorld.*</p>
      <h2 class="cost" style="font-weight: bold;">COSTO: 500 RAPTOREUMM, COSTO PARA RWC HOLDERS: 250 RAPTOREUM </h2>   
      <div class="spinner-border" role="status" v-if="balanceCargandoSpin">
               <span class="visually-hidden">Loading...</span>
              </div>   
      <h1 v-if="discount===false">RWC no encontrado en tu billetera. no se aplicarán descuentos.</h1>   
      <h1 v-if="discount===true">RWC encontrado en tu billetera! se aplicarán descuentos!</h1>      
      <div v-if="$store.state.totp===true" style="display: flex; width: 80%;  max-width: 80%;" >
      <p>2fa authentication:</p>
        <input type="text" placeholder="2fa code" v-model="$store.state.totpCode" >
      </div>

      <div class="buttons">
        <input type="button" v-on:click="createAsset()" value="CREATE">
        <input type="button" v-on:click="salir()" value="EXIT">
      </div>
    </div>
     </div>
 
    <div  class="modalMensajeDelCreador"  v-if=" $store.state.idioma!=false && $store.state.idioma=='english'">
     <div  class="half">
      <h1>Create NFT:</h1>
      <p style="font-weight: bold;">Enter the NFT name (it must be a name not used by any nft):</p>
      <input type="text" v-model="$store.state.createTokenName">
      <p style="font-weight: bold;">NFT description (tell the people what's your NFT about):</p>
      <textarea name="" id="" v-model="assetDescription"></textarea>

      <p style="font-weight: bold;">Select the NFT image:</p>
      <h3>*it must not exceed 2 MB.*</h3>
      <input type="file" accept-language="en" @change="handleFileUpload">
     </div> 

     <div class="half2">
      <div class="holder">
        <h1  class="holderElement" style="font-weight: bold;">Final result:</h1>
 <div class="card" :key="index">
            <img  v-if="image != ''" :src="image" alt="Uploaded Image">
            <div class="card-body">
    <h1  style="color:#000">{{$store.state.createTokenName }}</h1>
    <p class="card-text" style="color:#000">{{assetDescription}}</p>
  </div>
</div>
       

</div>
       
    
      <p style="margin-top: 1vh;font-weight: bold;font-size: small;">*Creating your NFT with us guarantees you direct access to our market. NO External NFT can be registered to RaptoreumWorld*</p>
      <h2 class="cost" style="font-weight: bold;">COST: 500 RAPTOREUM, RWC HOLDERS COST: 250 RAPTOREUM</h2>
      <div class="spinner-border" role="status" v-if="balanceCargandoSpin">
               <span class="visually-hidden">Loading...</span>
              </div>   
      <h1 v-if="discount===false">RWC not found in wallet. no discount will be applied.</h1>   
      <h1 v-if="discount===true">RWC found in wallet! discount will be applied!</h1>   
      <div v-if="$store.state.totp===true" style="display: flex; width: 80%;  max-width: 80%;" >
      <p>2fa authentication:</p>
        <input type="text" placeholder="2fa code" v-model="$store.state.totpCode" >
      </div>

      <div class="buttons">
        <input type="button" v-on:click="createAsset()" value="CREATE">
        <input type="button" v-on:click="salir()" value="EXIT">
      </div>
    </div>
     </div>
 
    
    
  </template>

  <script>
  import axios from 'axios'
import esArrayIterator from 'core-js/modules/es.array.iterator';
  import { io } from 'socket.io-client'
  import pendingAssetModal from '@/components/pendingAssetModal.vue'

  export default {
    name: 'modal',
    props: {},
    components: {
        pendingAssetModal
       },
    data() {
      return {
        file: null,  // Agregar una variable para almacenar el archivo seleccionado,
        image:"",
        assetDescription:"",
        discount:null,
        balanceCargandoSpin:false
      }
    },
    mounted() {
      // Tu código de montaje...
      this.$store.state.pendingToken.firstClick = true
      this.$store.state.pendingToken.clicked = true
    },
    methods: {
      // Otros métodos...
      handleFileUpload(event) {
      const file = event.target.files[0];
      this.image=URL.createObjectURL(file)
      // Verifica si se seleccionó un archivo
      if (!file) {
        alert('you must provide a file ERR: No se ha seleccionado ningún archivo.');
        this.validFile=false
        return;
      }
      // Verifica si el archivo es una imagen
      if (!file.type.startsWith('image/')) {
        alert('file must be image type ERR: EL ARCHVIO DEBE SER UNA IMAGEN.');
        this.validFile=false
        return;
      }
      // Verifica si el tamaño del archivo es aceptable (opcional)
      const maxSizeMB = 2; // Tamaño máximo permitido en megabytes
      if (file.size > maxSizeMB * 1024 * 1024) {
        alert(`The file is too big. ERR: EL ARCHIVO NO DEBE SOBREPASAR ${maxSizeMB} MB.`);
        this.validFile=false
        return;
      }     
      // Si pasa todas las validaciones, asigna el archivo a la variable
      this.validFile=true
      this.file = file;
    },
    async createAsset() {
        // Otros datos del formulario...
        if(this.validFile == true){
            let formData = new FormData();
        formData.append('file', this.file); // Agrega el archivo al formulario
        // Agrega otros datos al formulario si es necesario

        formData.append('assetname', this.$store.state.createTokenName);
        formData.append('totalsupply', 1);
        formData.append('acronym', "NFT");
        formData.append('description', this.assetDescription);
        formData.append('type', 'nft');
   
          formData.append('totp', this.$store.state.totpCode);
   
        let token=localStorage.getItem("token")
        axios.post('https://backend-raptoreum.world/createToken', formData,{
         headers: {
           'Content-Type': 'multipart/form-data',
           Authorization: `Bearer ${token}`,
         }
        }).then(response => {
          console.log("RESPONSE DEL BACK:", response)
          try {
            if(response.data=="pendingCreation"){
           alert("pending NFT creation / ¡CREACIÓN PENDIENTE!")
           window.location.href = 'https://raptoreumworld.com/?type=creations';
          }
          } catch (error) {
            console.log("ERROR AL EVALUAR LA DATA DE RESPONSE:",error)
          }  
            return
            // Puedes acceder a los datos de la respuesta usando response.data
          })
          .catch(error => {
console.log("ERROR CREATE ASSET:",error)
  if(!error.response){
    alert("you are not connected. try again later.  ERR: NO ESTÁS CONECTADO. INTENTA MAS TARDE.")
    return
  } else if(error.response && error.response.data){
 if(error.response.data == "multerError"){
      alert("file too large ERR: ARCHIVO MUY GRANDE")
    }
    if(error.response.data=="no tienes saldo suficiente"){
      alert("not enough balance ERR: BALANCE INSUFICIENTE")
    } else if(error.response.data=="notLogged"){
      alert("Please sign in  ERR: INICIA SESIÓN")
      return
    } else if(error.response.data=="error"){
      alert("there was an error creating your token.  ERR:  INTENTA MAS TARDE.")
      return
    }else if(error.response.data=="invalidTotp"){
      alert("INVALID 2FA CODE ERR: CODIGO 2FA INVALIDO")
    
    }else if(error.response.data=="notEnoughBalanceRWS"){
      alert("RWS %99 discount not enough balance ERR: NECESITAS 101 RTM PARA CREAR EL TOKEN CON 99% DE DESCUENTO")
    } 
    else if(error.response.data=="assetAlreadyInWallet"){
      if(localStorage.getItem("token")=="spanish"){
      alert("El nombre del asset coincide con un asset en tu billetera. registralo o olvidalo en el apartado de assets no registrados")
      }else if(localStorage.getItem("token")=="english"){
        alert("The asset name matches an asset in your wallet. Register it or forget it in the section of unregistered assets")    
      }
    } 
    else if(error.response.data=="expired"){
      this.$store.state.sessionExpired=true
    }else if(error.response.data=="nombreYaExiste"){
      alert("token name already exists - ERR: NOMBRE DEL TOKEN EN USO")
    } 
     else if(error.response.data=="tokenExists"){
      alert("token name already exists - ERR: NOMBRE DEL TOKEN EN USO")
      return
    }   else if(error.response.data=="pendingCreation"){
      console.log("EJECUTANDO PENDING CREATION:")
      this.$store.state.pendingToken.firstClick = false
      if(this.$store.state.idioma=='spanish'){
        this.$store.state.pendingToken.status = "Creación pendiente."
      }else if(this.$store.state.idioma=='english'){
        this.$store.state.pendingToken.status = "Pending token creation."
      }
    this.$store.state.pendingToken.clicked = true
      return
    } 
    else if(error.response.data == "blockedAccount"){
      alert("cannot create asset now. ERR: NO PUEDES CREAR UN ASSET AHORA")
      return
    }else if(error.response.data == "file-may-contain-virus"){
      alert("rejected. it may contain virus ERR: ARCHIVO RECHAZADO POR POSIBLE VIRUS")
    }
  
    else if(error.response.data && error.response.data.errors){
      if(error.response.data.errors[0].path == "description"){
        alert("too short description - ERR : LA DESCRIPCION ES MUY CORTA")
        return
      }else if(error.response.data.errors[0].path == "acronym"){
        alert("acronym should be 3 or 4 characters ERR: EL ACRONIMO DEBE SER DE 3 O 4 LETRAS")
        return
      }
    }

  }
});
        }else{
            alert("not valid file ERR: ARCHIVO NO VALIDO")
        }
       
      },
      async   getBalance(){
    this.balanceCargandoSpin=true
console.log("disparando get balance")
const config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
};
        axios.get('https://backend-raptoreum.world/app2/balance',config)
      .then(response => { 
console.log(response)
          this.$store.state.cargando=true
      this.$store.state.wallet.raptoreum=null
      this.$store.state.rtmBalance=0
      if(response.data.notRegisteredAssets != "empty"){
        this.$store.state.notRegisteredAssets=response.data.notRegisteredAssets
      }
   
  
        this.notLogged=false

        if(response.data.raptoreumData.address!='none'){
          
          this.$store.state.wallet.raptoreum=response.data.raptoreumData.address
          this.$store.state.rtmBalance=response.data.raptoreumData.balance
        }else if(response.data.raptoreumData.address=='none'){
          this.$store.state.wallet.raptoreum="none"
        }
  
       this.$store.state.totalAssets=response.data.todosLosAssets
       const raptoreumWorldCoin = this.$store.state.totalAssets.find(assetObj => assetObj.asset === "RAPTOREUMWORLDCOIN");
if (raptoreumWorldCoin) {
  this.$store.state.isRWC=true
  this.discount=true
} else {
  this.$store.state.isRWC=false
  this.discount=false
}
         this.$store.state.cargando=false
         this.balanceCargando=false
         this.balanceCargandoSpin=false
        })
        .catch(error => {
          this.discount=null
          this.$store.state.cargando=false
         this.balanceCargando=false
         this.balanceCargandoSpin=false
          if(error.response)
          {
            if(error.response.data)
            {
              if(error.response.data=="expired"){
                this.$store.state.sessionExpired=true
              }else if(error.response.data=="errorBalance")
              {
                alert("we couldn't get your data ERR: NO LOGRAMOS OBETENER TUS DATOS!")
              }else if(error.response.data=="serverDown"){
                this.$store.state.serverDown=true
              }
             }
          }
        });
      }
      ,
      salir(){ 
        //this.socket.disconnect()
        this.$store.state.registerNftModal=false
      },
      handleFileUpload(event) {
      const file = event.target.files[0];
      this.image=URL.createObjectURL(file)
      // Verifica si se seleccionó un archivo
      if (!file) {
        alert('you must provide a file ERR: No se ha seleccionado ningún archivo.');
        this.validFile=false
        return;
      }
      // Verifica si el archivo es una imagen
      if (!file.type.startsWith('image/')) {
        alert('file must be image type ERR: EL ARCHVIO DEBE SER UNA IMAGEN.');
        this.validFile=false
        return;
      }
      // Verifica si el tamaño del archivo es aceptable (opcional)
      const maxSizeMB = 5; // Tamaño máximo permitido en megabytes
      if (file.size > maxSizeMB * 1024 * 1024) {
        alert(`The file is too big. ERR: EL ARCHIVO NO DEBE SOBREPASAR ${maxSizeMB} MB.`);
        this.validFile=false
        return;
      }     
      // Si pasa todas las validaciones, asigna el archivo a la variable
      this.validFile=true
      this.file = file;
    },
      async createNFT() {
        // Otros datos del formulario...
        if(this.validFile == true){
            let formData = new FormData();
        formData.append('file', this.file); // Agrega el archivo al formulario
  
        // Agrega otros datos al formulario si es necesario
        formData.append('token', localStorage.getItem("token"));
        formData.append('assetname', this.$store.state.createTokenName);
        formData.append('totalsupply', this.$store.state.createTokenTotal);
        formData.append('acronym', this.$store.state.createTokenAcronym);
        formData.append('description', this.assetDescription);
  
        axios.post('https://backend-raptoreum.world/app2/uploadNFT', formData,{
         headers: {
           'Content-Type': 'multipart/form-data'
         }
        }).then(response => {
            alert("token created! :D SUCCESS: TOKEN CREADO! :D")
            return
            // Puedes acceder a los datos de la respuesta usando response.data
          })
          .catch(error => {

  if(!error.response){
    alert("you are not connected. try again later.  ERR: NO ESTÁS CONECTADO. INTENTA MAS TARDE.")
    return
  } else if(error.response && error.response.data){
    if(error.response.data=="no tienes saldo suficiente"){
      alert("not enough balance ERR: BALANCE INSUFICIENTE")
    } else if(error.response.data=="notLogged"){
      alert("Please sign in  ERR: INICIA SESIÓN")
      return
    } else if(error.response.data=="error"){
      alert("server is down. try again later.  ERR: SERVIDOR CAIDO. INTENTA MAS TARDE.")
      return

    }else if(error.response.data=="notEnoughBalanceRWS"){
      alert("RWS %99 discount not enough balance ERR: NECESITAS 101 RTM PARA CREAR EL TOKEN CON 99% DE DESCUENTO")
    } 
  
    else if(error.response.data=="expired"){
      this.$store.state.sessionExpired=true
    } else if(error.response.data=="tokenExists"){
      alert("nft name already exists - ERR: NOMBRE DEL NFT EN USO")
      return
    } 
    else if(error.response.data == "blockedAccount"){
      alert("cannot create asset now. ERR: NO PUEDES CREAR UN ASSET AHORA")
      return
    }
    else if(error.response.data && error.response.data.errors){
      if(error.response.data.errors[0].path == "description"){
        alert("too short description - ERR : LA DESCRIPCION ES MUY CORTA")
        return
      }else if(error.response.data.errors[0].path == "acronym"){
        alert("symbol should be 3 or 4 characters ERR: EL ACRONIMO DEBE SER DE 3 O 4 LETRAS")
        return
      }
    }
  }
});
        }else{
            alert("not valid file ERR: ARCHIVO NO VALIDO")
        }
       
      }
    }
  }
  </script>
    
      <style scoped>
 

  input{
    font-size: 3vh;
    height: 4vh;
  }
  
  h1,h2,h3,p{
    font-size: 3vh;
  }
  .description{
    overflow-y: scroll;
    max-height:35%;
    width:60%;
    word-wrap: break-word;
   font-weight: bold;
  }
      #app{
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
      }
      
  .buttons{
    display: flex;
    width: 80%;
    max-width: 80%;
  }
  .buttons input{
    width: 50%;
    max-width: 50%;
    height: min-content;
    font-size: 3vh;
  }
     
  .holder{
    display: flex;flex-direction: column;justify-content: center;width: 100%;align-items: center;
  }
      .modalMensajeDelCreador{
        display:flex;
        justify-content:center;
        flex-direction:row;
        align-items:center;
        position:fixed;
        z-index:100;
        left:0;
        top:0;
        width:100vw;
        height:100vh;
        background-color:rgb(0,0,0,0.9);
        display:flex;
        color:#fff;
        font-family: 'Poppins', sans-serif;
        text-align:center;
        font-size:3vh;
        overflow-y: scroll;
      }
   .card{
    width: 18rem;max-height: 69vh;overflow-y: scroll; 
   }
  .half,.half2{
    width: 50%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
      @keyframes spin {
        0% { transform: rotateY(0deg); }
        100% { transform: rotateY(360deg); }
      }
 @media (max-width:980px) {
    .card{
    width: 18rem;max-width: 18rem;max-height: 30vh;overflow-y: scroll; 
   }
  .description{
    overflow-y: scroll;
    max-height:100%;
    width:25%;
    word-wrap: break-word;
   font-weight: bold;
  }
  .holderElement{
    width: 20%;
  }
  input{
    font-size: 2vh;
    height: 3vh;
  }
  .image-preview{
    border-radius: 50%;
    width:50px;
    height:50px;

  }
  .holder{
    display: flex;flex-direction: row;justify-content: center;width: 100%;align-items: center;height: 70%;border:2px solid #666;
  }
  h1,h2,h3,p{
    font-size: 2vh;
  }
  .half{
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 45%;
  }
    .half2{
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 55%;
    }

    .modalMensajeDelCreador{
          flex-direction: column;
        }
    .buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
    height: 20%;
  }
  .buttons input{
    width: 50%;
  }
  .cost{
    height: 10%;
  }
      }
      @media (max-width:980px) {
        .image-preview{
    border-radius: 50%;
    width:30px;
    height:30px;

  }
      }
  
      </style>
      