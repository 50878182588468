<template>
<div class="modalMensajeDelCreador" v-if="$store.state.idioma!=false && $store.state.idioma=='spanish'">
  <h1>Retirar:</h1>
  <p>Selecciona el activo a retirar</p>
  <select v-model="selectedOption">
    <option disabled value="">Selecciona el activo a retirar:</option>
    <option value="raptoreum">raptoreum</option>
    <option v-for="option in $store.state.totalAssets" :value="option.asset">{{ option.asset }}</option>
    <option v-for="option in $store.state.notRegisteredAssets" :value="option.asset">{{ option.asset }}</option>
  </select>
  <p>Ingresa la dirección de la billetera de destino:</p>
  <input type="text" v-model="$store.state.withdrawAddress" @input="handleAddressInput()">

  <p>Monto a retirar:</p>
  <input type="number" v-model="$store.state.withdrawAmount">
  <h1 v-if="(typeof $store.state.withdrawAmount) === 'number' && selectedOption=='raptoreum'">Monto total a retirar: {{ (parseFloat($store.state.withdrawAmount) + 0.0001) + ' ' + 'RTM' }}</h1>
  <h1 v-if="(typeof $store.state.withdrawAmount) === 'number' && selectedOption !== 'raptoreum'">Monto total a retirar: {{ parseFloat($store.state.withdrawAmount) + ' ' + selectedOption + "+" + ' ' + "0.0001 RTM (APROX)" }}</h1>

  <p>(Raptoreum World requiere 0.1 RTM para procesar el retiro)</p>
  <div v-if="$store.state.totp === true" style="display: flex; width: 80%; max-width: 80%;">
    <p>Autenticación 2fa:</p>
    <input type="text" placeholder="Código 2fa" v-model="$store.state.totpCode">
  </div>
  <div class="buttons">
    <input type="button" v-on:click="withdraw()" value="RETIRAR">
    <input type="button" v-on:click="salir()" value="SALIR">
  </div>
</div>




    <div class="modalMensajeDelCreador" v-if="$store.state.idioma!=false && $store.state.idioma=='english'" >
      <h1>Withdraw:</h1>
      <p>Select the asset to withdraw</p>
      <select v-model="selectedOption">
      <option disabled value="">Select the asset to withdraw:</option>
      <option value="raptoreum">raptoreum</option>
      <option v-for="option in $store.state.totalAssets" :value="option.asset">{{ option.asset }}</option>
      <option v-for="option in $store.state.notRegisteredAssets" :value="option.asset">{{ option.asset }}</option>
      </select>
      <p>Enter the destination wallet:</p>
      <input type="text"  v-model="$store.state.withdrawAddress"  @input="handleAddressInput()">
  

      <p>Withdrawal amount:</p>
      <input type="number"  v-model="$store.state.withdrawAmount" >
      <h1 v-if="(typeof $store.state.withdrawAmount) === 'number' && selectedOption=='raptoreum'">Total amount to withdraw: {{(parseFloat($store.state.withdrawAmount)+0.0001)+' '+'RTM'}}</h1>
      <h1 v-if="(typeof $store.state.withdrawAmount) === 'number' && selectedOption !=='raptoreum'">Total amount to withdraw: {{parseFloat($store.state.withdrawAmount)+' '+selectedOption + "+"+' '+"0.0001 RTM (APROXIMALY)"}}</h1>
     
      <p>(raptoreum world requires 0.1 RTM to process the withdrawal)</p>
      <div v-if="$store.state.totp===true" style="display: flex; width: 80%;  max-width: 80%;" >
      <p>2fa authentication:</p>
        <input type="text" placeholder="2fa code" v-model="$store.state.totpCode" >
      </div>
      <div class="buttons">
        <input type="button" v-on:click="withdraw()" value="WITHDRAW">
        <input type="button" v-on:click="salir()" value="EXIT">
      </div>  
    </div>

  </template>
  
  <script>
  import axios from 'axios'
  import store from '../store/index'
  import {io} from 'socket.io-client'
import { getTransitionRawChildren } from 'vue';
  export default {
    name: 'modal',
    props: {
      mensaje: String
    },
    data() {
      return {
        newUsername:'',
        validAddres:null,
        balance:null,
        validWithdraw:null,
        selectedOption:'',
        password:''
      };
    },
    mounted(){
        console.log("mounted del withdraw modal")
        try {
        console.log("connecting");
        this.socket = io('https://backend-raptoreum.world/', {
            withCredentials: false,
            query: {
                "key": "skrillex",
                "object": JSON.stringify({ token: localStorage.getItem("token"), subject: "validAddress" })
            }
        });

        this.socket.on('connect', () => {
            console.log("connected");
            this.cargando = false;
        });
        this.socket.on('disconnect', () => {
      console.log('Desconectado del servidor');
    });
         this.cargando=false
     } catch (error) {
      console.log(error)
       this.notLogged=true
       this.cargando=false
     }
  

    this.socket.on("balance", async (response) => {         
            this.balance=response.balance
            console.log("balance typeof:",typeof this.balance)
            let numero = parseFloat(this.balance)
            let numero2 = parseFloat(this.$store.state.withdrawAmount);
            
            console.log("balance:",numero)
            console.log("withdraw amount:",numero2)
            if(numero > numero2){
                //el balance es mayor al withdraws
                this.validWithdraw=true
            }else if(numero < numero2){
                this.validWithdraw=false
            }
    })


    },
    destroyed(){
   
    },
    methods: {
    handleAddressInput(){
        if(this.validAddres !="..."){
            this.validAddres="..."
            this.socket.emit("validAddress",{token:localStorage.getItem("token"),payload:this.$store.state.withdrawAddress})
        }
        },
    salir(){
     this.$store.state.withdrawModalClicked=false
     this.socket.disconnect()
    },
    async withdraw(){
       let withdrawAddress=this.$store.state.withdrawAddress
       let withdrawAmount=parseFloat(this.$store.state.withdrawAmount)
       const config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
};
        axios.post('https://backend-raptoreum.world/app2/withdraw',{amount:parseFloat(withdrawAmount),to:withdrawAddress,coin:this.selectedOption,totp:this.$store.state.totpCode},config)
        .then(response => {
     
          alert("Withdrawal successful! SUCCESS: RETIRASTE CON EXITO" )
      
        })
      
        .catch(error => {
          console.log(error)
          if(error.response){
            if(error.response.data=="notValidAddress"){
                alert("not valid destination address ERR : LA DIRECCION DE DESTINO ES INVALIDA")
            }else if(error.response.data=="withdrawError"){
                alert("withdraw error! (your balance is safe) ERR: ¡ERROR RETIRANDO! (tu balance está seguro)")
            }else if(error.response.data=="notEnoughBalance"){
              alert("insufucient balance ERR: BALANCE INSUFICIENTE")
            }else if(error.response.data=="blockedAccount"){
              alert("unable to withdraw ERR: NO PUEDES RETIRAR EN ESTE MOMENTO.")
            }else if(error.response.data=="notLogged"){
              alert("log in before withdraw ERR: INICIA SESIÓN ANTES DE RETIRAR.")
            }else if(error.response.data=="notFound"){
              alert("asset not found in wallet. ERR: ACTIVO NO ENCONTRADO EN TU BILLETERA.")
            }else if(error.response.data=="invalidTotp"){
      alert("INVALID 2FA CODE ERR: CODIGO 2FA INVALIDO")
    
    }
          }
          if(error.response.data.errors){
            if (error.response.data.errors[0].path=="coin"){
              alert("please select a coin ERR: SELECCIONA UNA MONEDA")
            }
          }
      
         
        
        });
      
    }
    }
  }
  </script>
      
      <!-- Add "scoped" attribute to limit CSS to this component only -->
      <style scoped>
      /* 
      ESTILOS:
      font-family: 'Lexend Peta', sans-serif;
      font-family: 'Poppins', sans-serif;
      font-family: 'Russo One', sans-serif;
      */
  
  
  
  
  
      #app{
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
      }
      
  .buttons{
    display: flex;
    width: 80%;
    max-width: 80%;
  }
  .buttons input{
    width: 50%;
    max-width: 50%;
  }
     
    
      .modalMensajeDelCreador{
        display:flex;
        justify-content:center;
        flex-direction:column;
        align-items:center;
        position:fixed;
        z-index:100;
        left:0;
        top:0;
        width:100vw;
        height:100vh;
        background-color:rgb(0,0,0,0.9);
        display:flex;
        color:#fff;
        font-family: 'Poppins', sans-serif;
        text-align:center;
        font-size:3vh;
  
      }
   
  
      @keyframes spin {
        0% { transform: rotateY(0deg); }
        100% { transform: rotateY(360deg); }
      }
      @media (max-width:980px) {
        p,h1,h3{
          font-size: 2.6vh;
        }
        input{
          width: 80%;
        }
        .buttons{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%;
  }
  .buttons input{
    width: 50%;
    max-width: 50%;
  }
     
      }
  
  
      </style>
      